import React, { } from 'react'

function QrApp(props:PropsValue) {
 
  return(

       
    <div id="help">
  <header id="header">
	<h1>CSVデータのアップロードについて</h1>
</header>

<article id="contents">
	<section className="contentsin">
		<section className="contents-box">
			<div className="title-box">
				<h2>ＱＲコードについて</h2>
			</div>
			<div className="comment-box">
				<p>「可変項目」の内どれか一つをQRコードに変換可能です。<br />
				半角英数500文字以内のデータを変換可能です。</p>

				<p className="explanation"><strong>▼ QRコードに変換したい項目にチェック</strong><br /></p>
				<div className="img">
					<img src="../images/help/qr01.jpg" alt="ずらし加工の図" />
				</div>
			</div>
		</section>


	</section>
</article>
    </div>


  )
}

export default QrApp
