import React, { useState, useEffect } from 'react'
import usePrefectreState from '../prefectre/usePrefectreState'
import Message from '../message/Message'

function InqueriForm(props:PropsValue) {

  const [options, setOptions] = useState([])
  const [message, setMessage] = useState(null)
  const changeHandler = props.changeHandler
  const {prefectures,getPrefectures,getPrefectureName} = usePrefectreState()

  
  useEffect(() => {
    getPrefectures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    setMessage(props.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.message]);


  useEffect(() => {
    const _ops = prefectures.map(pref => {
      return (
        <option value={pref.id} selected={props.item && props.item.prefecture_id === pref.id ? "selected": ""}>{pref.prefecture_name}</option>
      )
    })
    const __ops = [<option value={null}>選択してください</option>].concat(_ops)
    setOptions(__ops)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[prefectures, props.item]);

  

  return(
    <table className="form-table address-table inq">
                <tr>
                  <th>
                    <span className="thname">会員ID（メールアドレス）</span>
                  </th>
                  <td>
                  {props.is_confirm ? props.item.member_id :
                    <div className="textbox">
                      <div>
                       
                        <input type="" name="member_id" id="member_id" className="inputbox02" placeholder="" value={props.item.member_id} onChange={changeHandler}/>
                       
                        </div>
                    </div>
                     }
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="thname">会社名</span>
                  </th>
                  <td>
                  {props.is_confirm ? props.item.company_name :
                    <div className="textbox">
                      <div>
                    
                        <input type="" name="company_name" id="company_name" className="inputbox02" placeholder="" value={props.item.company_name} onChange={changeHandler}/>
                      
                        </div>
                    </div>
                    }
                  </td>
                </tr>
                <tr  className={Message.hasMessge(message, "name") ? "err" : null}>
                  <th>
                    <span className="thname">お名前</span>{props.is_confirm ? null : <span className="required">必須</span>}
                  </th>
                  <td>
                  {props.is_confirm ? props.item.name :
                    <div className="textbox">
                      <div>
                      
                        <input type="" name="name" id="name" className="inputbox02" placeholder="" value={props.item.name} onChange={changeHandler}/>
                      </div>
                       <ul className="error-box">
                                   {message ? Message.getMessgeFromList(message,"name") : null}
                      </ul>
                    </div>
                    }
                  </td>
                </tr>
                <tr className={Message.hasMessge(message, "name_kana") ? "err" : null}>
                  <th>
                    <span className="thname">お名前（フリガナ）</span>{props.is_confirm ? null :<span className="required">必須</span>}
                  </th>
                  <td>
                  {props.is_confirm ? props.item.name_kana :
                    <div className="textbox">
                      <div>
                     
                        <input type="" name="name_kana" id="name" className="inputbox02" placeholder="" value={props.item.name_kana} onChange={changeHandler}/>
                      </div>
                       <ul className="error-box">
                                   {message ? Message.getMessgeFromList(message,"name_kana") : null}
                      </ul>
                    </div>
                    }
                  </td>
                </tr>
                <tr  className={Message.hasMessge(message, "email") ? "err" : null}>
                  <th>
                    <span className="thname">メールアドレス</span>{props.is_confirm ? null :<span className="required">必須</span>}
                  </th>
                  <td>
                  {props.is_confirm ? props.item.email :
                    <div className="textbox">
                      <div>
                     
                        <input type="" name="email" id="mailaddress" className="inputbox02" placeholder="" value={props.item.email} onChange={changeHandler}/>
                      </div>
                      <ul className="error-box">
                                   {message ? Message.getMessgeFromList(message,"email") : null}
                      </ul>
                    </div>
                    }
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="thname">電話番号</span>
                  </th>
                  <td>
                  {props.is_confirm ? props.item.tel :
                    <div className="textbox">
                      <div>
                     
                        <input type="" name="tel" id="tel" className="inputbox02" placeholder="" value={props.item.tel} onChange={changeHandler}/>
                      </div>
                    </div>
                    }
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="thname">郵便番号</span>
                  </th>
                  <td>
                  {
                        props.is_confirm ? props.item.post1 && props.item.post2 ? props.item.post1 + "-"  + props.item.post2 : null
                        :
                    <div className="textbox">
                    
                      <div>
                     
                        <div>
                        <input type="" name="post1" id="post1" className="inputbox03" placeholder="" value={props.item.post1} onChange={changeHandler}/>&nbsp;-&nbsp;
                        <input type="" name="post2" id="post2" className="inputbox04" placeholder=""value={props.item.post2} onChange={changeHandler}/> 
                        </div>
                     
                      </div>
                    </div>
                      } 
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="thname">都道府県</span>
                  </th>
                  <td>
                  {props.is_confirm ? getPrefectureName(props.item.prefecture_id) :
                    <select name="prefecture_id" id="prefecture_id" className="inputbox02" placeholder="" value={props.item.prefecture_id} onChange={changeHandler}  >
                                  {
                                    options
                                  }
                    </select>
                  }</td>
                </tr>
                <tr>
                  <th>
                    <span className="thname ls10">住所</span>
                  </th>
                  <td>
                  {props.is_confirm ? props.item.address1 :
                    <div className="textbox">
                      <div>
                      
                        <input type="" name="address1" id="address1" className="inputbox02" placeholder="" value={props.item.address1} onChange={changeHandler}/> 
                      </div>
                    </div>
                    }
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="thname">マンション名など</span>
                  </th>
                  <td>
                  {props.is_confirm ? props.item.address2 :
                    <div className="textbox">
                      <div>
                        <input type="" name="address2" id="address2" className="inputbox02" placeholder=""  value={props.item.address2} onChange={changeHandler}/> 
                      </div>
                    </div>
                    }
                  </td>
                </tr>
                <tr  className={Message.hasMessge(message, "memo") ? "err" : null}>
                  <th>
                    <span className="thname">お問い合わせ内容</span>{props.is_confirm ? null :<span className="required">必須</span> }
                  </th>
                  <td>
                  {props.is_confirm ? props.item.memo :
                    <div className="textbox">
                      <div>
                      
                                        <textarea id="memo" type="memo" className="form-control" name="memo"  value={props.item.memo} required onChange={changeHandler}></textarea>
                      </div>
                      <ul className="error-box">
                                   {message ? Message.getMessgeFromList(message,"memo") : null}
                      </ul>
                    </div>
                    }
                  </td>
                </tr>
              </table>
  )
}

export default InqueriForm


