import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function PrivacyApp(props:PropsValue) {

  document.title = "個人情報保護方針｜圧着はがきDM印刷";


  return(

        <div id="page" className="page privacy">
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
           <h1 className="stitle-line">個人情報保護方針</h1>
          </section>

          <section className="s-contentsin2 box-contents">
		<div className="box">
			<h2>個人情報保護及び特定個人情報等の適正な取扱いに関する基本方針・基本理念</h2>
			<p>私たちあさひ高速印刷株式会社は、印刷という仕事を通じて地域・社会のコミュニケーションや文化の発展のため誠心誠意お手伝いをし、寄与することが重要と考えております。<br/>
			そのために、経営者をはじめ社員の一人ひとりが「情報」というものの取扱いに関し、誰からも信頼されるようにならなければと考えております。<br/>
			特に個人情報及び特定個人情報等の適正な取扱いや保護の徹底を図ることは、私たちの社会的責任であると思います。そのことは、顧客や一般の方々、地域の信頼性を糧とする事業活動の重要な「基本理念」であります。</p>
			<p>従いまして、基本理念に基づき次の通り個人情報保護方針を定め、これを具体的に実行するため、個人情報保護マネジメントシステムを確立し実行、維持致します。</p>
		</div>


		<div className="box">
			<h2>個人情報保護及び特定個人情報等の適正な取扱いに関する基本方針</h2>
			<ol className="ol-pt1">
				<li>
					<p>当社は、印刷業務に関連する顧客からの個人情報及び従業員の個人情報並びに特定個人情報等について、重要性とそれを保護する責務を自覚し、個人情報及び特定個人情報等の取得・利用及び提供・委託を適正に行います。<br/>
					又、あらかじめ明らかにした利用目的の範囲を超えて、取扱うことは致しません。 そのために、管理規程に定め管理致します。</p>
				</li>
				<li>
					<p>個人情報及び特定個人情報等に関する法令、国が定める指針その他関連する規範を常に最新の状態に維持するとともに、全社的にこれを遵守し企業の社会的責任を全うします。</p>
				</li>
				<li>
					<p>個人情報及び特定個人情報等の漏えい、滅失又はき損などを防止するため、適切な安全対策を講ずるとともに是正処置を実施します。</p>
				</li>
				<li>
					<p>当社は、「お客様相談窓口」を設置し、個人情報及び特定個人情報等保護に関するお問い合わせに速やかに対応致します。お問い合わせは以下にお願いします。</p>
					<div className="boxin">
						TEL：06-6448-7521　FAX：06-6447-1896<br/>
						E－mail：asahi@ag-media.co.jp<br/>
						URL： http://www.ag-media.jpd
					</div>
				</li>
				<li>
					<p>個人情報及び特定個人情報等保護に関するマネジメントシステムを確立し、実施し、点検並びに監査し、見直しを各自が行うことによって継続的に改善を図ります。</p>
				</li>
				<li>
					<p>個人情報及び特定個人情報等保護に関するマネジメントシステムに違反した場合は毅然たる態度で対応致します。</p>
				</li>
			</ol>
			<p className="sign">制定日：2008年8月1日<br/>
			改訂日：2016年5月25日<br/>
			あさひ高速印刷株式会社<br/>
			代表取締役社長　岡　達也</p>
		</div>


		<div className="box">
			<h2>個人情報及び特定個人情報等の取り扱いについて</h2>
			<ol className="ol-pt1">
				<li>
					<p>当社では以下の目的で個人情報及び特定個人情報等を利用します。 当社が、ご本人より直接書面によって取得する個人情報及び特定個人情報等を、それ以外の方法によって取得する場合の個人情報及び特定個人情報等ならびに保有個人データは、下記業務及びそれに関連、付随する目的のために利用いたします。<br/>
					適切に取得された個人情報等のみ取扱います。<br/>
					また、業務の受託に伴って委託元から提供（取扱いの委託）された個人情報等について、これを当該委託元との契約の目的の達成に必要な範囲内で利用します。</p>
					<ol className="ol-pt2">
						<li>印刷製本業務</li>
						<li>製品の発送業務</li>
						<li>サービスに関する情報のお知らせ</li>
						<li>請求書やサンプル・資料・商品券等の送付</li>
						<li>個人情報を含む編集データ（バックアップ）等の管理</li>
						<li>当社に対する問い合わせ等への対応</li>
						<li>社員及び扶養家族・退職者・採用応募者等の雇用および人事管理</li>
						<li>特定個人情報等に関しては社会保障制度、税務関係ならびに防災・減災関連</li>
					</ol>
				</li>
				<li>
					<p>当社は、ご本人の同意を得ている場合や法令にもとづく場合或は業務遂行にあたって必要な場合を除き、取得した個人情報及び特定個人情報等を第三者に提供することはいたしません。</p>
				</li>
				<li>
					<p>上記１－（１）・（２）・（３）・（４）・（５）の利用目的を達成するため、業務の一部を業務委託契約を締結した業務委託先に委託する場合があります。この場合でも当社が定めた基準に従い適切な管理を行います。</p>
				</li>
				<li>
					<p>当社への個人情報及び特定個人情報等の開示、訂正・追加・削除、利用の停止または第三者への提供の停止、利用目的の通知（以下「開示等」という。）を希望される場合は郵送（現金書留）にて受付けます。下記「開示等の求めの申し出先」までご連絡ください。<br/>
					受付担当者が「個人情報等開示依頼書」を送付いたしますのでご返送ください。<br/>
					なお、本人または代理人のご確認書類の同封が必要ですのでご注意ください。
					下記の場合、ご依頼内容に対して、全部ないし一部対応できないことがありますので、その際は、その旨を遅滞なく通知するものとします。 </p>
					<ul>
						<li>依頼書の記載内容に不備がある場合。</li>
						<li>ご本人あるいは代理人による申請の代理人が確認できない場合。</li>
						<li>ご依頼の対象が「開示対象個人情報等」に該当しない場合。</li>
						<li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合。</li>
						<li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合。</li>
						<li>法令に違反することとなる場合。</li>
						<li>当該ご依頼のうち、開示のご請求及び利用目的の通知のご請求につきましては、当社所定の手数料1,000円（消費税込み）をご負担いただきますのであらかじめご了承ください。</li>
					</ul>
					<div className="boxin">
						<h3>（１）開示等の求めの申し出先 </h3>
						<div className="inner">
							<h4>１）上記１－（１）（２）（３）（４）（５）（６）項に関しての送付先</h4>
							<p className="address">〒５５０－０００２　大阪市西区江戸堀２－１－１３<br/>
							あさひ高速印刷株式会社　顧客相談窓口責任者<br/>
							ＴＥＬ．０６－６４４８－７５２１</p>
							<dl>
								<dt>・ご提出いただくもの</dt>
								<dd> &#9312;「個人情報開示依頼書」（様式Ⅰ）<br/>
								&#9313;ご本人確認のための書類の写し<br/>
								&#9314;法定代理人の場合は、上記&#9312;に加え、法定代理権があることを確認する書類の写し<br/>
								&#9315;手数料　1,000円（消費税含む） </dd>
							</dl>
						</div>
						<div className="inner">
							<h4>２）上記１－（７）（８）項に関しての送付先</h4>
							<p className="address">〒５５０－０００２　大阪市西区江戸堀２－１－１３<br/>
							あさひ高速印刷株式会社　総務部部門情報管理者<br/>
							ＴＥＬ．０６－６４４３－８８５１</p>
							<dl>
								<dt>・ご提出いただくもの</dt>
								<dd>&#9312;「個人情報等開示依頼書」（様式Ⅱ）<br/>
									&#9313;ご本人確認のための書類の写し<br/>
									&#9314;法定代理人の場合は、上記&#9313;に加え、法定代理権があることを確認する書類の写し<br/>
									&#9315;手数料　1,000円（消費税含む）</dd>
							</dl>
						</div>
					</div>
				</li>
				<li>
					当社への個人情報及び特定個人情報等の取扱に関するご相談や苦情等のお問い合わせについては、下記の窓口までご連絡いただきますよう、お願いいたします。
					<div className="boxin">
						<dl>
							<dt>&#x25C6;上記１－（１）（２）（３）（４）（５）項に関しての連絡先</dt>
							<dd>あさひ高速印刷株式会社　顧客相談窓口責任者<br/>
							ＴＥＬ．０６－６４４８－７５２１（受付時間：平日９時～１７時）</dd>
							<dt>&#x25C6;上記１－（７）（８）項に関しての連絡先</dt>
							<dd>あさひ高速印刷株式会社　総務部部門情報管理者<br/>
							ＴＥＬ．０６－６４４３－８８５１（受付時間：平日９時～１７時）</dd>
						</dl>
					</div>
				</li>
				<li>
					認定個人情報保護団体について<br/>
					当社は、下記の認定個人情報保護団体の対象事業者です。当社の個人情報の取り扱いに関する苦情については、下記へお申し出いただくこともできます。
					<div className="boxin">
						<dl>
							<dt>&#x25C6;認定個人情報保護団体の名称</dt>
							<dd>一般財団法人日本情報経済社会推進協会</dd>
							<dt>&#x25C6;苦情の解決の申出先</dt>
							<dd>個人情報保護苦情相談室</dd>
							<dt>&#x25C6;苦情の解決の連絡先</dt>
							<dd>〒106-0032　東京都港区六本木１丁目９番９号六本木ファーストビル内<br/>
							ＴＥＬ．０３ー５８６０ー７５６５　／　０１２０−７００−７７９ <br/><span className="small">※当社の商品・サービスに関する問合せ先ではございません。</span></dd>
						</dl>
					</div>
				</li>
				<li>
					必要な書類をご提出いただけない場合は業務遂行が困難なためお断りする場合があります。また、採用にあたっては、採用選考の対象外となりますのでご了承ください。<br/>
					<span className="right">以上</span>
				</li>
			</ol>
			<p className="sign">制定日：2008年8月1日<br/>
			改訂日：2016年10月24日<br/>
			あさひ高速印刷株式会社　個人情報保護管理者　経営企画室課長</p>
		</div>
	</section>



          </article>
          <Footer />
        </div>


  )
}

export default PrivacyApp
