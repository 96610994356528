import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function DeliveryApp(props:PropsValue) {

  document.title = "料金・発送・お支払いについて｜圧着はがきDM印刷";

  return(

        <div id="page" className="page payment">
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin topcomment">
            <h1 className="stitle-line">お支払い・発送について</h1>

            <ul className="box-button">
              <li><a href="#pyment">お支払いについて</a></li>
              <li><a href="#postage">送料・納期・発送について</a></li>
              <li><a href="#cancel">キャンセルについて</a></li>
            </ul>
          </section>

          <section className="s-contentsin box-contents" id="pyment">
            <h2 className="stitle-unerline">お支払いについて</h2>
            <div className="box-contentsin">
              <p>ヤマト運輸の宅急便コレクト（代引き）サービスにて、代金引換え、クレジットカード払いをお選びいただけます。</p>
              <dl>
                <dt>
                  <div>
                    代金引換え<span>［30万円（税込）まで］</span>
                  </div>
                </dt>
                <dd>
                  <p>ヤマト運輸から商品をお届けする際に、現金にてお支払いいただき商品を受け取る方法です。<br/>
                  30万円（税込）までの商品を現金でのお支払いいただけます。<br/>
                  30万円（税込）を超える場合は、クレジットカード払いをご利用ください。</p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <div>
                    クレジットカード払い
                  </div>
                </dt>
                <dd>
                  <p>クロネコWEBコレクトサービスにてクレジットカードでお支払いいただけます。<br/>
                  ご利用いただけるクレジットカードは、下記となります。</p>
                  <img src="/images/payment/card.png" alt="お支払い可能なクレジットカード" />
                </dd>
              </dl>
            </div>
          </section>

          <section className="s-contentsin box-contents" id="postage">
            <h2 className="stitle-unerline">送料・納期・発送について</h2>
            <div className="box-contentsin">
              <h3>■送料について</h3>
              <ul>
                <li>送料（国内1カ所/1箱）</li>
                <li>全国（沖縄を除く）・・・￥1,500×梱包数</li>
                <li>沖縄・・・￥3,000×梱包数</li>
              </ul>
            </div>

            <div className="box-contentsin">
              <h3>■納期について</h3>
              <p>入稿いただいたデータを確認し、問題がなければ印刷工程に進みます。<br/>
              印刷までには、<strong>5営業日</strong>いただいております。<br/>
              <br/>
              データに問題がある場合は、個別にメールまたはお電話にてご連絡させていただきます。</p>
            </div>

            <div className="box-contentsin">
              <h3>■発送について</h3>
              <p>ヤマト運輸にて発送いたします。</p>

              <h4>●印刷完了後の配達所要日数</h4>
              <table className="tb01">
                <tr>
                  <th>1日<br/>【翌日】</th>
                  <td>新潟県・富山県・石川県・福井県・群馬県・埼玉県・千葉県・東京都・神奈川県・山梨県・長野県・岐阜県・静岡県・愛知県・三重県・滋賀県・京都府・大阪府・兵庫県・奈良県・和歌山県・鳥取県・島根県・岡山県・広島県・山口県・徳島県・香川県・愛媛県・高知県</td>
                </tr>
                <tr>
                  <th>２日<br/>【翌々日】</th>
                  <td>青森県・岩手県・宮城県・秋田県・山形県・福島県・茨城県・栃木県・福岡県・佐賀県・長崎県・熊本県・大分県・宮崎県・鹿児島県</td>
                </tr>
                <tr>
                  <th>3日以上</th>
                  <td>北海道・沖縄県</td>
                </tr>
              </table>

              <h4>●梱包について</h4>
              <div className="box-img">
                <div className="l">
                  <img src="/images/payment/cardboard.png" alt="発送用段ボール【宅配100サイズ】" />
                </div>
                <div className="r">
                  <p>発送用段ボール【宅配100サイズ】に梱包してお届けいたします。<br/>
                  1つの段ボールに梱包できる枚数は、次の通りです。</p>
                  <table className="tb02">
                    <thead>
                      <tr>
                        <th className="none"> </th>
                        <th>90kg</th>
                        <th>110kg</th>
                        <th>135kg</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>V型</th>
                        <td className="none"></td>
                        <td colspan="2">3,600枚<span>（16.3kg）</span></td>
                      </tr>
                      <tr>
                        <th>Z型</th>
                        <td colspan="2">3,200枚<span>(18kg)</span></td>
                        <td className="none"></td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="min">※100枚の厚み（マット）／箱の高さ330 で計上</p>
                </div>
              </div>

            </div>
          </section>


          <section className="s-contentsin box-contents" id="cancel">
            <h2 className="stitle-unerline">キャンセルについて</h2>
            <div className="box-contentsin">
              <p>完全受注生産のため、一度注文されました商品は、返品・交換・キャンセルはできません。<br/>
              ただし、商品にあきらかな不備があった場合・ご注文いただきました商品と違う商品が届いた場合に限り、商品の交換を承ります。<br/>
              交換の場合は、商品到着後7日以内に下記内容を必ずご記入の上、「お問い合わせ」よりご連絡くださいますようお願い申し上げます。<br/>
              <br/>
              　1．お名前<br/>
              　2．メールアドレス<br/>
              　3．注文番号<br/>
              　4．交換理由</p>
            </div>
          </section>

          </article>
          <Footer />
        </div>


  )
}

export default DeliveryApp
