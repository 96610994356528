import React, {  useEffect, useState } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'

import InqueriProps from './InqueriProps'


import usePostcodeState from '../postcode/usePostcodeState'
import InqueriForm from './InqueriForm'
import useInqueriState from './useInqueriState'
function InqueryConfirmApp(props:PropsValue) {

  document.title = "お問い合わせ 内容のご確認｜圧着はがきDM印刷";

  const [item, setItem] = useState(new InqueriProps())
  const {addresses, getAddressInfo} = usePostcodeState()
  const {inqueri, created,loadFromCookie, create, removeFromCookie} = useInqueriState()
  useEffect(() => {

    loadFromCookie()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
   if(inqueri){
    const new_item = new InqueriProps(inqueri)
    setItem(new_item)
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inqueri]);

  useEffect(() => {
    if(created){
      removeFromCookie()
      window.location.href = "/inquiry/success"
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[created]);


  useEffect(() => {

    if(addresses && addresses.length > 0){
      const address = addresses[0]
      const _item = item.set("prefecture_id",  address.prefcode)
      setItem(_item.set("address1", address.address2 + address.address3))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addresses]);


  const changeHandler =(e) => {
    const target = e.target
    console.log(item)
    const new_item = item.set(target.name, target.value)
    setItem(new_item)

    if(target.name === "post1" || target.name === "post2"){
      var code = "";
      if(target.name === "post1" && item.post2){
        code = target.value + item.post2
      }
      else if(target.name === "post2" && item.post1){
        code = item.post1 + target.value
      }

       if(code.length === 7){
        getAddressInfo(code)
      }

    }

  }


  const btnClickHandler = () => {

    create(item)
  }

  const back = () =>{

    window.location.href = "/inquiry"

  }



  return(

        <div id="page" className="page contact">
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
            <h1 className="stitle">お問い合わせ</h1>
            <h2 class="stitle3">お問い合わせ内容のご確認</h2>
		        <p>お問い合わせの内容をご確認ください。問題なければ、「送信する」ボタンを押してください。</p>
          </section>




          <section className="s-contentsin2 form-box">
            <div className="box">

              <InqueriForm changeHandler={changeHandler} item={item} is_confirm={true} />



              <div className="buttonbox pt2">
              <div class="button gr">
                <div onClick={back}>修正する</div>
              </div>
                <div className="button or">
                  <div onClick={btnClickHandler}>送信</div>
                </div>
              </div>
            </div>
          </section>



          </article>
          <Footer />
        </div>


  )
}

export default InqueryConfirmApp
