import React, { } from 'react'

function BackgroundApp(props:PropsValue) {
 
  return(

       
    <div id="help">
    <header id="header">
	<h1>宛名面、中面の背景画像（PDF）について</h1>
</header>

<article id="contents">
	<section className="contentsin">
		<section className="contents-box">
			<div className="title-box">
				<h2>宛名面、中面の背景画像（PDF）について</h2>
			</div>
			<div className="comment-box">
				<p>PDFで保存いただきました背景データを宛名面、中面それぞれアップロードしてください。<br/>
				※背景用PDFをご用意されていないお客様は、「入稿方法」から背景作成用のイラストレータのデータをダウンロードして、PDFを作成してから入稿をお進めください。<br/>
				アップロードしますと、印刷範囲（トンボの内側）が、プレビューで表示されます。</p>

			</div>
		</section>


	</section>
</article>
    </div>


  )
}

export default BackgroundApp
