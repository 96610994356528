
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

const CartAction = {  

  loadFromCookie(callback){
    const id =  cookie.load("current_cart_id")
    callback(id)
  },

  removeFromCookie(){
    cookie.remove("current_cart_id",{ path: '/' })
  },

  get( token, id, handler, errorHandler){
    fetch(config.get("API_PATH") +  "/api/cart/items/"  + id   , {
      method: "GET",
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      
      if(data && !data.error){
        handler(data)
      }else{
        if(errorHandler) errorHandler(data)
      }
    })
  },

  async create(token, item, handler, errorHandler){
    const formData = new FormData();
    formData.append('csv', item.csv);
    formData.append('specification', item.specification);
    formData.append('paper', item.paper);
    formData.append('thickness', item.thickness);
    formData.append('process_type', item.process_type);
    formData.append('processing', item.processing);
    formData.append('plan', item.plan);

    if(item.memo && item.memo !== "")
      formData.append('memo', item.memo);
    if(item.sheet && item.sheet > 0)
      formData.append('sheet', item.sheet);
    if(item.base_order_id && item.base_order_id !== '') {
      formData.append('base_order_id', item.base_order_id);
    }

     await fetch(config.get("API_PATH") + "/api/cart/items"  , {
      method: "POST",
      headers: {
       'Authorization': 'Bearer ' + token,
      },
      body: formData
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        cookie.save('current_cart_id', data.id, { path: '/' })
        handler(data)
      }else{
        errorHandler(data)
      }
    })
  },

  

  updateItem(token,  item, callback){
    fetch(config.get("API_PATH") + "/api/cart/items/" + item.id  , {
      method:  "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":403}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        callback(data)
      }else{
        //errorHandler(data)
      }
    })
  },

  duplicateFromOrder(order) {
    const item = {
      csv: order.csv,
      specification: order.specification,
      paper: order.paper,
      thickness: order.thickness,
      process_type: order.process_type,
      processing: order.processing,
      plan: order.plan,
      memo: order.memo,
    };
    if(order.sheet && order.sheet > 0) {
      item.sheet = order.sheet;
    }

    return item
  }
}

export default CartAction
