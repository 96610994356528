import React, {  useEffect } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'
import UserMenu from '../menu/UserMenu'

function PasswordResetUserSuccessApp(props:PropsValue) {

  document.title = "パスワード再設定 完了｜DM圧着はがきDM印刷";

  const {token, auth} = useAuthState()


  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



  return(

        <div id="page" className="member password reset"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
          <UserMenu selectMenuItem="register" />
          <section className="s-contentsin2 topcomment">
            <h1 className="stitle">パスワード再設定</h1>
            <p>パスワードの再設定を完了しました。</p>
            </section>
           
            </article>
          <Footer />
        </div>
  

  )
}

export default PasswordResetUserSuccessApp
