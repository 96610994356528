import React ,{} from 'react'
import pageTop from "../images/common/pagetop.png"
import footerPayPc from "../images/common/footer-pay-pc.png"
import footerPaySp from "../images/common/footer-pay-sp.png"
import footerQualification from "../images/common/footer-qualification.png"
import footerLogo from "../images/common/footer-logo.png"

function Footer(props:PropsValue) {

  return(
    <footer id="footer">
    <div id="page-top">
      <a href="#page"><img src={pageTop} alt="ページトップへ" /></a>
    </div>
    <section className="s-contentsin announcement-box">
      <div className="box">
        <h2>ご利用いただけるお支払い方法</h2>
        <div className="boxin">
          <img src={footerPayPc} alt="ご利用いただけるお支払い方法" className="pc" /><img src={footerPaySp} alt="ご利用いただけるお支払い方法" className="sp" />
          <div className="link">
            <a href="/delivery#pyment">▶ 詳しくはこちら</a>
          </div>
      </div>
      </div>
      <div className="box">
        <h2>納期について</h2>
        <div className="boxin">
          <p>12時（正午）以降のご入稿は翌営業日からの受付となります。</p>
          <div className="link">
            <a href="/delivery#postage">▶ 詳しくはこちら</a>
          </div>
        </div>
      </div>
      <div className="box">
        <h2>お問い合わせ</h2>
        <div className="boxin">
          <div class="link">
  					<a href="/inquiry">▶ お問い合わせはこちら</a>
  				</div>
        </div>
      </div>
    </section>

    <section className="link-box">
      <div className="box">


      <ul>
        <li><a href="/order" className="bold">商品一覧</a>
        <ul><li><a href="/order/#printing01">宛名あり印刷</a></li>
        <li><a href="/order/#printing02">宛名あり・可変項目印刷</a></li>
        <li><a href="/order/#printing03">宛名なし印刷</a></li>
        </ul>
        </li>
      </ul>


      </div>
      <div className="box">
        <ul>
                  <li><a href="/order">入稿の流れ</a></li>
                  <li><a href="/howto">入稿方法（データ作成方法）</a></li>
                  <li><a href="/delivery">料金・発送・お支払いについて</a></li>
                  <li><a href="/faq">Q&A</a></li>
                  <li><a href="/company">会社概要</a></li>
                  <li><a href="/inquiry">お問い合わせ</a></li>
        </ul>
      </div>
      <div className="box">
        <ul>
          <li><a href="/law">特定商取引法に基づく表記</a></li>
          <li><a href="/privacy">個人情報保護方針</a></li>

        </ul>
        <div className="qualification">
          <img src={footerQualification} alt="あさひ高速印刷オンラインショップ 圧着はがきDM印刷" />
        </div>
      </div>
    </section>

    <section className="s-contentsin address-box">
      <div className="address-boxin">
        <div className="l">
          <h2><img src={footerLogo} alt="" /></h2>
        </div>
        <div className="r">
          <address>
            運営会社：あさひ高速印刷株式会社<br />
            <span className="post">〒550-0002</span><span>大阪市西区江戸堀2丁目1番地13号</span><span>あさひ高速印刷ビル</span>
          </address>
          <div className="data">
            <dl>
              <dt>営業時間</dt>
              <dd>9：00～16：00</dd>
            </dl>
            <dl>
              <dt>定休日</dt>
              <dd>土・日・祝</dd>
            </dl>
          </div>
        </div>
      </div>
    </section>

    <section className="s-contentsin copy-box">
      <p id="copy">© 2022 あさひ高速印刷オンラインショップ 圧着はがきDM印刷  INC. All Rights Reserved.</p>
    </section>
  </footer>
  )
}

export default Footer
