import React, { } from 'react'

function CsvApp(props:PropsValue) {
 
  return(

       
    <div id="help">
    <header id="header">
      <h1>CSVデータのアップロードについて</h1>
    </header>

    <article id="contents">
      <section className="contentsin">
        <section className="contents-box">
          <div className="title-box">
            <h2>CSVデータのアップロードについて</h2>
          </div>
          <div className="comment-box">
            <p>宛名や可変項目は、CSVでアップロードいただけます。<br/>
            最大件数2万件までで、弊社指定の形でCSVを出力したものをアップロードしてください。<br />
            次の場合、エラーとして表示されますので、ご注意ください。</p>
            <ul>
              <li>通し番号がない</li>
              <li>通し番号が被っている</li>
              <li>会社名とお名前、両方とも空白の行がある</li>
              <li>QRコードとして選択した列に、半角英数以外の文字がある</li>
            </ul>
          </div>
        </section>


      </section>
    </article>
    </div>


  )
}

export default CsvApp
