import React, { useState, useEffect } from 'react'

import Header from '../../header/HeaderForStep'
import useAuthState from '../../auth/useAuthState'
import progress_image from "../../images/step/step-step2.png"
import ConfirmDetailItem from './ConfirmDetailItem'
import ConfirmValiableItem from './ConfirmValiableItem'
import useCartState from '../cart/useCartState'
import CsvPreview from './CsvPreview'
import useAdditionalPlacementState from '../additional_placement/useAdditionalPlacementState'
import RequestValue from '../RequestValue'

function ConfirmDetailApp(props:PropsValue) {

  document.title = "アップロードした可変データの確認｜圧着はがきDM印刷";


  //const {  cart_id } = useParams();
  var _is_loading = false
  const {token, auth} = useAuthState()
  const [item, setItem] = useState(null)
  const [error, setError] = useState(null)

  const [valibleErrorMessage, setsValibleErroMessage] = useState(null)
  const [additional_item_list, setAdditionalItemList] = useState([])
 // const [list_updated_at, setListUpdatedAt] = useState(null)
  const {cart, current_cart_id, getCart, getCurrentCartId} = useCartState()
  const {additional_placemnets, createAdditionalItems} = useAdditionalPlacementState()

  useEffect(() => {
    auth()
    getCurrentCartId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);

  useEffect(() => {
    getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {
    setItem(cart)
    //getBtnClass()
    if(cart)
      validateCsv(cart.csv)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cart ]);

  /**
  useEffect(() => {
    getBtnClass()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at]);
 */

  useEffect(() => {

    if(additional_placemnets) {
      moveTo("step3")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[additional_placemnets ]);

  const getCurrentCart = () =>{
    if(token && current_cart_id && !cart && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
    }
  }

  const validate = () => {
    if(error) return

    if(cart.plan === RequestValue.PLAN_NO_ATENA || cart.plan === RequestValue.PLAN_ATENA){
      moveTo("step3")
      return
    }
    ///自由項目が埋まっているかどうか

    var is_validated = true
    const _v_list_1 = additional_item_list.filter((_item) => _item.placement === null || _item.placement === "0")

    if(_v_list_1.length !== 0){
      setsValibleErroMessage(_v_list_1)
      is_validated = false
    }else{
      setsValibleErroMessage(null)
    }
    if(is_validated){
      createAdditionalItems(token, cart.id, additional_item_list)
    } else{
      const element = document.getElementById("vali")
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }


  const moveTo = (step) => {
    window.location.href = "/order/" + step
  }
  /**
  const getBtnClass = () => {

    const _v_list_1 = additional_item_list.filter(_item => _item.placement === null || _item.placement === "0")
    if(!cart || error !== null ||  ( cart.plan === RequestValue.PLAN_ATENA_NAKA && _v_list_1.length !== 0)){
      setNextBtnClass("btn btn-light button next text-white off")
      setNextBtnDisable("disabled")
    }else{
      setNextBtnClass("btn btn-light button next text-white fs-2")
      setNextBtnDisable(null)
    }

  } */

  const setList = (data) => {
    setAdditionalItemList(data)
    //setListUpdatedAt(new Date())
  }


  const validateCsv = (list) => {
    const _errors = []
    const _index_lsit = []
    if(list){
      for(var i = 0 ; i < list.length; i++){
        const _item = list[i]
        _index_lsit.push(_item[0])
        const _element_error = []
        if(_item[1] === "") _element_error.push("郵便番号")
        if(_item[2] === "") _element_error.push("都道府県")
        if(_item[3] === "") _element_error.push("市町村")
        if(_item[4] === "") _element_error.push("番地")
        if(_item[6] === "" && _item[7] === "" && _item[8] === "") _element_error.push("会社名または名前")
        if(_element_error.length !== 0) _errors.push({index:i, errors:_element_error})
      }
      //IDの重複
      const _group_index_list = Array.from(new Set(_index_lsit));


      if(_errors.length === 0 && _group_index_list.length === _index_lsit.length) return true
        //getBtnClass()
       const error_messages = _errors.map(_itme => {
          return (<li>{_itme.index + 1}行目 {_itme.errors.join(",")}に空白があります。</li>)
        })
        if(_group_index_list.length !== _index_lsit.length) error_messages.push(<li>通し番号に重複があります。</li>)

        setError(error_messages)
    }

  }

  return(

        <div id="step" className="step step2">
          <Header token={token} />
              <article id="contents">
                <section className="contentsin now-step">
                    <img src={progress_image} alt="CSV連携確認" />
                </section>

                <section className="s-contentsin topcomment">
                    <h1>アップロードした可変データの確認</h1>
                    <p className="max-width-800 mb-40">アップロードいただいたCSVを各項目と連携しました。<br />
                  問題がないかご確認の上、レイアウト編集へお進みください。</p>
                </section>
                {item ? <ConfirmDetailItem csv={item.csv} /> : null}
                <div id="vali" >{item && item.plan === RequestValue.PLAN_ATENA_NAKA ? <ConfirmValiableItem cart={item} error_msg={valibleErrorMessage} setList={setList} /> : null }</div>



	              <section class="s-contentsin2 section">
                  <h2 className="titlebox">
                      <div className="titleboxin">アップロードしたＣＳＶの確認</div>
                  </h2>
                  <p>アップロードしたCSVを変更したい場合は、前のステップに戻り、アップロードしなおしてください。</p>
                </section>

                {error ?

                <div className="error-box-csv">
                  <p>下記のセルに空白があります。空白を埋めてから、「印刷内容を確認」ページで再度CSVをアップロードしてください。</p>
                  <ul className="error-box">
                  {  error }
                  </ul>
                </div>



                : null }


                {item ?  <CsvPreview csv={item ? item.csv : null} /> : null }
              </article>


             <footer id="footer-step">
                <div className="s-contentsin">
                    <div className="l">
                        <div className="button forward">
                            <div onClick={ () => moveTo("step1")}>
                                <span>印刷内容を確認へ</span>
                            </div>
                        </div>

                    </div>

                    <div className="r">
                      <div className="button next">
                        <div onClick={validate}>
                          <span>レイアウト編集へ</span>
                        </div>
                      </div>
                    </div>

                </div>
            </footer>
        </div>



  )
}

export default ConfirmDetailApp
