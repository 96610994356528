import React, { useState, useEffect } from 'react'
import Request from '../Request';
import RequestValue from '../RequestValue';
import useRequestState from '../print_detail/useRequestState';
import useCartState from '../cart/useCartState';

import useDeliveryState from '../../delivery/useDeliveryState';
import DeliveryWindow from '../../delivery/DeliveryWindow';
import usePrefectreState from '../../prefectre/usePrefectreState';


function OrderForm(props:PropsValue) {
  
  const [item, setItem] = useState(null)
  const [delivery_item, setDeliveryItem] = useState(null)

  const [price, setTotalPrice] = useState(null)
  const [shipping, setSipping] = useState(null)
  const [options, setOptions] = useState([])
  const [delivelyWindow, setDeliveryWindow] = useState(false)
  const {total_price, delivery_price,  getTotalPrice} = useRequestState()
  const {prefectures,getPrefectures} = usePrefectreState()
  const {cart, cart_updated_time, updateCartItem} = useCartState()
  const {delivery_list, created_delivery_id, delivery_list_updated_at, getDeliveryList,getDeliveryItemById, createDelivery, updateDelivery, deleteDerivery} = useDeliveryState()

  useEffect(() => {
 
    if(props.item && props.item.id){
    
      var order_form = new Request(props.item)
     
      var order_form_ship = order_form.set("shipping", order_form.shipping ? order_form.shipping  : RequestValue.DELIVERY_ME).set("payment_method",RequestValue.PAYMENT_METHOD_CASHON)  
     
      setItem(order_form_ship)
      setSipping(order_form_ship.shipping)
      setDeliveryItem(props.profile)
      getDeliveryList(props.token)
      getPrefectures()
      
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);  


  useEffect(() => {
    
    if(item){
      setSipping(item.shipping)
    }
      
  },[item ]);

  useEffect(() => {
   
    var delivery_pref_id = null
    
    if(shipping){
   
      if(shipping === RequestValue.DELIVERY_ME &&props.profile&&props.profile.main_member_at ){
        delivery_pref_id = props.profile.prefecture_id
      }else{
        const delivery = getDeliveryItemById(Number(shipping))
        if(delivery){
          delivery_pref_id =delivery.prefecture_id
        }
    
      }
    }
   
    if(delivery_pref_id)
      getTotalPrice(props.token, props.item, delivery_pref_id, props.item.thickness)
    else{
      //getTotalPrice(props.token, props.item)
      //setDeliveryPrice(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[shipping]);


  useEffect(() => {
    if(cart_updated_time){
      props.moveToNextStep(cart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cart_updated_time]);

  useEffect(() => {

    if(delivery_price){
      setTotalPrice(total_price + delivery_price)  
    }else{
      setTotalPrice(total_price)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[total_price,delivery_price]); 

  useEffect(() => {
    
    if(props.profile && props.profile.main_member_at){
      setDeliveryItem(props.profile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.profile]);

  useEffect(() => {
    if(created_delivery_id){
      const _item = item.set("shipping", created_delivery_id)
      setItem(_item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[created_delivery_id]);
  


  useEffect(() => {
    if(delivery_list){
      const _ops_head = [<option value="notselected" >配送先を選択</option>]
      const _ops_add = delivery_list.map(delivery => {
        return (
          <option value={delivery.id} selected={item && Number(item.shipping)=== Number(delivery.id) ? "selected": ""}>{delivery.name}</option>
        )
      })
      const _ops = _ops_head.concat(_ops_add)
      _ops.push( <option value="0" >新規に配送先を登録</option>)
      setOptions(_ops)
      if(Number(item.shipping) > 0){
        setDeliveryItem(getDeliveryItemById(Number(item.shipping)))
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[delivery_list_updated_at])



  const changeHandler = (e) => {

    const target = e.target
   
    if(target.name === "shipping"){
      if(target.value==="notselected") {
        //setDeliveryItem(null) 
        return null
      }
      
      if(target.value === RequestValue.DELIVERY_ME) {
        setDeliveryItem(props.profile)
      }else if(target.value === "0"){
        setDeliveryWindow(true)
        window.scrollTo(0, 0);
        return null
      }else if(Number(target.value) > 0){
        setDeliveryItem(getDeliveryItemById(Number(target.value)))
      }
      const _item = item.set(target.name, target.value)
      setItem(_item)
    }else{
      const _item = item.set(target.name, target.value)
      setItem(_item)
    }
    
    
  }

  const submitNewDelivery = (item) => {
    
    if(item.id){
      updateDelivery(props.token, item)
    }else{
      setDeliveryWindow(null)
      createDelivery(props.token, item)
    }
     
  }

  const updateItem = (e) => {
    e.preventDefault();
   
    if(item.shipping  && item.payment_method) {
      var new_item = item
      if(item.shipping ===  RequestValue.DELIVERY_REGISTERED ){
        new_item = item.set("shipping",RequestValue.DELIVERY_ME )
      }
      updateCartItem(props.token, new_item)
    }
   
  }

  const deleteDeliveryItem = (item) => {
    deleteDerivery(props.token, item)
  }
  /**
  const moveTo = (step) => {
    window.location.href = "/order/" + step
  }

  const getBackStepBtnLink = () => {
    console.log(item)
    return item && item.pdf_front_path && item.pdf_back_path && item.plan !== RequestValue.PLAN_NO_ATENA? "step6" : "step3"
  }

  const getBackStepBtnLabel = () => {
    return item && item.pdf_front_path && item.pdf_back_path && item.plan !== RequestValue.PLAN_NO_ATENA? "最終印刷データへ" : "レイアウト編集へ"
  }
 */

  return(
      <div>
        {delivelyWindow ? 
        <DeliveryWindow delete={deleteDeliveryItem} delivery_list={delivery_list} submit={submitNewDelivery} close={() => setDeliveryWindow(null)} />
         : null}

         {item === null ? null : 
         <div>
          <section className="section form  delivery-list">
              <h2 className="titlebox mb-p0">
                <div class="titleboxin">ご注文フォーム<span class="required">必須</span></div>
              </h2>


             
              <table className="form-table">
                  <tr className="">
                      <th>
                          <span className="ls10">仕様</span>
                      </th>
                      <td>
                          <div className="list specification">
                              {
                                item.specification === RequestValue.SPECIFICATION_V ?
                                <div>
                                  <img src="../images/step/specification-v.png" alt="" />
                                  <span>
                                      <input type='radio' name='specification' value={RequestValue.SPECIFICATION_V}  id='specification01'  checked={true} />
                                      <label htmlFor='specification01'>{RequestValue.SPECIFICATION_V}</label>
                                  </span>
                                </div>
                                :
                                <div>
                                  <img src="../images/step/specification-z.png" alt="" />
                                  <span>
                                      <input type='radio' name='specification' value={RequestValue.SPECIFICATION_Z} id='specification02' checked={true} />
                                      <label htmlFor='specification02'>{RequestValue.SPECIFICATION_Z}</label>
                                  </span>
                              </div>

                              }
                              
                              
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <th>
                          <span className="ls10">用紙</span>
                      </th>
                      <td>
                          <div className="list">
                              <div>
                                  <span>
                                      <input type='radio' name='paper'  id='paper01' value={RequestValue.PAPER_MAT}  checked={item.paper === RequestValue.PAPER_MAT ? "checked" : "" } onChange={changeHandler}/>
                                      <label for='paper01'>{RequestValue.PAPER_MAT}</label>
                                  </span>
                              </div>
                              
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <th>
                          用紙の厚み
                      </th>
                      <td>
                          <div className="list">
                              { item.specification !== RequestValue.SPECIFICATION_V ?
                              <div>
                                  <span>
                                    <input type='radio' name='thickness'  id='thickness01' value={RequestValue.THICKNESS_90}  checked={item.thickness === RequestValue.THICKNESS_90 ? true : false } onChange={changeHandler}/>
                                      <label for='thickness01'>{RequestValue.THICKNESS_90}</label>
                                  </span>
                              </div>
                              : null }
                              <div>
                                  <span>
                                      <input type='radio' name='thickness' value={RequestValue.THICKNESS_110}  checked={item.thickness === RequestValue.THICKNESS_110 ? true : false } onChange={changeHandler} />
                                      <label for='thickness02'>{RequestValue.THICKNESS_110}</label>
                                  </span>
                              </div>
                              { item.specification !== RequestValue.SPECIFICATION_Z ?
                              <div>
                                  <span>
                                      <input type='radio' name='thickness' value={RequestValue.THICKNESS_135}  checked={item.thickness === RequestValue.THICKNESS_135 ? true : false} onChange={changeHandler}/>
                                      <label for='thickness03'>{RequestValue.THICKNESS_135}</label>
                                  </span>
                              </div>
                              : null}
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <th>
                          加工方法
                      </th>
                      <td>
                          <div className="list">
                              <div>
                                  <span>
                                      <input type='radio' name='processing' id='processing01'  value={RequestValue.PROCESSING_ZURASHI}  checked={item.processing === RequestValue.PROCESSING_ZURASHI ? "checked" : "" } onChange={changeHandler} />
                                      <label for='processing01'>{RequestValue.PROCESSING_ZURASHI}</label>
                                  </span>
                              </div>
                          </div>
                        </td>
                  </tr>

                  <tr>
                      <th>
                          圧着方法
                      </th>
                      <td>
                          <div className="list">
                              <div>
                                  <span>
                                      <input type='radio' name='process_type' id='process_type01'  value={RequestValue.PROCESS_TYPE_NORMAL}  checked={item.process_type === RequestValue.PROCESS_TYPE_NORMAL ? "checked" : "" } onChange={changeHandler} />
                                      <label for='process_type01'>{RequestValue.PROCESS_TYPE_NORMAL}</label>
                                  </span>
                              </div>
                          </div>
                        </td>
                  </tr>

                  <tr className="">
                      <th>
                          印刷プラン
                      </th>
                      <td data-trigger="plan">
                          <div className="input-button-plan">
                              {
                                  item.plan === RequestValue.PLAN_ATENA  ?
                                  <div>
                                    <span>
                                        <input type='radio' name='plan' id='plan01'  value={RequestValue.PLAN_ATENA}  checked={true} />
                                        <label for='plan01'>
                                            <span>{RequestValue.PLAN_ATENA }</span>
                                            <span>宛名CSV+背景データが必要です</span>
                                        </label>
                                    </span>
                                  </div>
                                  : item.plan === RequestValue.PLAN_ATENA_NAKA ?

                                  <div>
                                    <span>
                                          <input type='radio' name='plan' id='plan02'  value={RequestValue.PLAN_ATENA_NAKA}  checked={true }  />
                                          <label for='plan02'>
                                              <span>宛名あり＋可変項目印刷</span>
                                              <span>CSVデータ+背景データが必要です</span>
                                          </label>
                                      </span>
                                  </div>
                                  :
                                  <div>
                                    <span>
                                        <input type='radio' name='plan' id='plan03'  value={RequestValue.PLAN_NO_ATENA}  checked={true } />
                                        <label for='plan03'>
                                            <span>{RequestValue.PLAN_NO_ATENA }</span>
                                            <span>背景データが必要です</span>
                                        </label>
                                    </span>
                                  </div>

                              }
                          </div>
                      </td>
                  </tr>
                  <tr className="">
                      <th>
                          <span className="ls10">枚数</span>
                      </th>
                      <td>
                          <div className="textbox">
                              <div>  
                                {item.sheet ? item.sheet.toLocaleString() : null}　枚
                              </div>
                              
                          </div>
                      </td>
                  </tr>

                  <tr>
                      <th>配送方法</th>
                      <td>
                          <div className="list vertical">
                              <div>
                                  <span>
                                      <input type='radio' name='shipping' value={RequestValue.DELIVERY_ME} checked={item.shipping === RequestValue.DELIVERY_ME ? "checked" : "" } onChange={changeHandler}/>
                                      <label for='shipping01'>{RequestValue.DELIVERY_ME}</label>
                                  </span>
                              </div>
                              <div>
                                  <span>
                                      <input type='radio' name='shipping' value={RequestValue.DELIVERY_REGISTERED} id='shipping02'  checked={item.shipping === RequestValue.DELIVERY_REGISTERED ||  Number(item.shipping) > 0 ? "checked" : "" } onChange={changeHandler}/>
                                      <label for='shipping02'>{RequestValue.DELIVERY_REGISTERED}</label>
                                  </span>
                              </div>
                              
                          </div>
                      </td>
                  </tr>
                  
                </table>
               
          </section>
      
          <section id="memo" className="s-contentsin2 section my-5 py-5">
              <h2 className="titlebox">
                  <div className="titleboxin">備考欄</div>
              </h2>
              <div className="box">
                  <p className="mb-p20">※配送元を変更の場合は、こちらにご記入ください</p>
                  <textarea name="memo" className="textarea" onChange={changeHandler} value={item.memo ? item.memo : ""} />
              </div>
          </section>
          

          <section className="s-contentsin2 section">
          <h2 className="titlebox mb-p0">
            <div className="titleboxin">配送先<span class="required">必須</span></div>
          </h2>

      

            <table className="form-table address-table">
            <tbody>
             
            {item &&( item.shipping ===  RequestValue.DELIVERY_REGISTERED ||  Number(item.shipping) > 0) ? 
                  
                <tr>
                  <th>
                      <span className="thname">配送先を選択してください</span>
                  </th>
                  <td>
                    <select name="shipping" id="prefecture_id" className="inputbox02" placeholder="" value={item.prefecture_id} onChange={changeHandler}  >
                      {
                                        options
                      }
                    </select>
                  </td>
                </tr>
               : null }
              {delivery_item ? 
              <tr>
                  <th>
                      <span className="thname">会社名</span>
                  </th>
                  <td>
                  {delivery_item.company_name}
                  </td>
              </tr>
              :null}
              {delivery_item ? 
              <tr>
                  <th>
                      <span className="thname">お名前</span>
                  </th>
                  <td>
                  {delivery_item.name} 
                  </td>
              </tr>
               :null}
              {delivery_item ? 
              <tr>
                      <th>
                          <span className="thname">お名前（フリガナ）</span>
                      </th>
                      <td>
                      {delivery_item.name_kana} 
                      </td>
              </tr>
               :null}
              {delivery_item ? 
              <tr>
                      <th>
                          <span className="thname">郵便番号</span>
                      </th>
                      <td>
                      {delivery_item.post1}-{delivery_item.post2}
                          
                      </td>
              </tr>
               :null}
              {delivery_item ? 
              <tr>
                      <th>
                          <span className="thname">都道府県</span>
                      </th>
                      <td>
                        { prefectures && prefectures.find(_item => Number(_item.id) === Number(delivery_item.prefecture_id)) ? prefectures.find(_item => Number(_item.id) === Number(delivery_item.prefecture_id)).prefecture_name : null}
                      </td>
              </tr>
               :null}
              {delivery_item ? 
              <tr>
                      <th>
                          <span className="thname ls10">住所</span>
                      </th>
                      <td>
                      {delivery_item.address} 
                      </td>
              </tr>
               :null}
              {delivery_item ? 
              <tr>
                      <th>
                          <span className="thname">マンション名など</span>
                      </th>
                      <td>
                      {delivery_item.address_add} 
                      </td>
              </tr>
               :null}
              {delivery_item ? 
              <tr>
                      <th>
                          <span className="thname">電話番号</span>
                      </th>
                      <td>
                      {delivery_item.tel} 
                      </td>

              </tr>
            : null}
            </tbody>
            </table>
        
     
      </section>
      <section className="s-contentsin2 section delivery-list">
              <h2 className="titlebox">
                  <div className="titleboxin">決済方法<span className="required">必須</span></div>
              </h2>
      
              <div className="box payment">
                  <div className="list">
                      { price < 300000 ? 
                      <div>
                          <span>
                              <input type='radio' name='payment_method' value={RequestValue.PAYMENT_METHOD_CASHON} id='payment01' checked={item.payment_method === RequestValue.PAYMENT_METHOD_CASHON ? "checked" : "" } onChange={changeHandler} />
                              <label for='payment01'>{RequestValue.PAYMENT_METHOD_CASHON}</label>
                          </span>
                      </div>
                      : null }
                      <div>
                          <span>
                              <input type='radio' name='payment_method' value={RequestValue.PAYMENT_METHOD_CREDIT} id='payment02' checked={item.payment_method === RequestValue.PAYMENT_METHOD_CREDIT ? "checked" : "" } onChange={changeHandler} />
                              <label for='payment02'>{RequestValue.PAYMENT_METHOD_CREDIT}</label>
                          </span>
                      </div>
                  </div>
              </div>
          </section>

          <footer id="footer-step">
              <div className="s-contentsin">
              <div class="l"></div>
              <div className="r">
              <div className="totalprice">
                <div>
                  <span className="total">合計金額<sup>※</sup></span>
                  <span className="price">{price ? price.toLocaleString() : null}</span>
                </div>
                  
              </div>
                      <div className={item.shipping && item.payment_method  ? "button next" : "button next off"} >
                          <div onClick={updateItem}>
                              <span>最終確認へ</span>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
        </div>
        }
        </div>

  ) 
}

export default OrderForm
//{item.shipping === RequestValue.DELIVERY_REGISTERED ||  Number(item.shipping) > 0? 
                            