import React, { } from 'react'

function MypageApp(props:PropsValue) {
 
  return(

       
    <div id="help">
   
   <header id="header">
	<h1>マイページについて</h1>
</header>

<article id="contents">
	<section className="contentsin">
		<section className="contents-box">
			<div className="title-box">
				<h2>再入稿</h2>
			</div>
			<div className="comment-box">
				<p>ステータスが「納品完了」の時に操作できます。</p>

				<p><strong>【宛名あり、宛名あり+可変項目印刷】の再入稿</strong><br/>
				●新しいCSVで再入稿・・・<br/>
				新しいCSVで1000件以上、20000件<sup>※</sup>以内を再入稿いただけます。<br/>
				※CSVの行数(1行目の項目をのぞく)が枚数となります。<br/>
				<br/>
				●再入稿・・・<br/>
				前回入稿したCSVと同じものを入稿いただけます。</p>

				<p><strong>【宛名なし印刷】の再入稿</strong><br/>
				前回入稿した数と同じ枚数を再入稿、印刷でます。</p>

			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>背景データ確認</h2>
			</div>
			<div className="comment-box">
				<p>入稿時にアップロードいただいた背景データをご確認いただけます。</p>
			</div>
		</section>

		<section className="contents-box">
			<div className="title-box">
				<h2>入稿データ削除</h2>
			</div>
			<div className="comment-box">
				<p>ステータスが「納品完了」の時に操作できます。<br/>
				背景やCSVなど入稿データをすべて削除いたします。削除後は、データを復活することはできませんのでご注意ください。</p>
			</div>
		</section>

		<section className="contents-box">
			<div className="title-box">
				<h2>サンプルPDFをダウンロード</h2>
			</div>
			<div className="comment-box">
				<p>背景やCSVなど入稿データをすべて削除いたします。削除後は、データを復活することはできませんのでご注意ください。</p>
			</div>
		</section>

	</section>
</article>
    </div>


  )
}

export default MypageApp
