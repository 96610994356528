import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';

import question_image from "../../images/common/question.png"
import step2_img02 from "../../images/step/step2-img01.png"

import RequestValue from '../RequestValue'
import ValiableItemElement from "./ValiableItemElement"
import AdditionalPlacement from '../additional_placement/AdditionalPlacement'


function ConfirmValiableItem(props:PropsValue) {

  const [additional_item_list, setAdditionalItemList] = useState([])
  const [additional_items, setAddtionalItems] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {
   
    var nums = props.cart.csv[0].length  - config.get("CSV_FIXED_LENGTH")
    nums = nums > 3 ? 3 : nums;
    const items = (new Array(nums)).fill(null).map((item, index) => {
      //const data = props.cart.csv.map(item => {return item[(config.get("CSV_FIXED_LENGTH") + index)]})
      return new AdditionalPlacement({column_name:index})
    }).filter(_item => _item !== null)
   
    setAdditionalItemList(items)
  },[props.cart]);

  

  useEffect(() => {
    console.log(props.error_msg)
    if(props.error_msg && props.error_msg.length > 0) {
      setMessage(
        <ul class="error-box">
			    <li>配置する面を指定してください</li>
		    </ul>
      )
    }
    updateAddtionalItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.error_msg]);


  useEffect(() => {
    updateAddtionalItems()
    props.setList(additional_item_list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[additional_item_list]);


  const errorHandler = (e) => {
    setMessage(e)
  }

  const updateAddtionalItems =  () => {
 
    var nums = props.cart.csv[0].length  - config.get("CSV_FIXED_LENGTH")
    nums = nums > 3 ? 3 : nums;
    
    const _selected_qrs = additional_item_list.filter(item => item.qr_code === 1) 
    const _selected_qr = _selected_qrs.length === 0 ? null : _selected_qrs[0]
 
    const items = (new Array(nums)).fill(null).map((item, index) => {
      const _item = additional_item_list[index]
      const _error = props.error_msg && props.error_msg.findIndex(item => item.column_name === _item.column_name) >= 0  
      const selectable = _selected_qr === null || _item.qr_code === 1 ? true :false 
      const data = props.cart.csv.map(item => {return item[(config.get("CSV_FIXED_LENGTH") + index)]})
      return <ValiableItemElement error={_error} index={index} data={data} max_length={nums} specification={props.cart.specification} selectable={selectable} plan={props.cart.plan} item={_item} changeHandler={changeHandler} setError={errorHandler}/>
    })
    setAddtionalItems(items) 
  }

 

  const changeHandler = (index, item) => {

    additional_item_list[index] = item
    setAdditionalItemList(additional_item_list)
    props.setList(additional_item_list)
    updateAddtionalItems()
  }

  return(
    <section className="s-contentsin2 section">
      <h2 className="titlebox">
          <div className="titleboxin">宛名以外の可変箇所の指定</div>
      </h2>

    <p className="mb-p40">
    可変項目印刷
        <a href="/help/variable" target="_blank" className="icon">
            <img src={question_image} alt="" />
        </a>
        をご希望の場合は、連携させたいCSVの列と配置したい面（各折面の指定を確認）を選択してください。<br />
        最大3項目までお選びいただけます。<br/>
        ＱＲコードに
        <a href="/help/qr" target="_blank" className="icon">
            <img src={question_image}  alt="" />
        </a>
        変更されたい場合は、「ＱＲコードに変更」にチェックを入れてください。<br />
        ※ＱＲコードへは、1項目のみ変更可能です。
    </p>
    { props.cart ? 
    <div className="imgbox mb-p50">
      <img src={ props.cart.specification === RequestValue.SPECIFICATION_Z ?  step2_img02 :step2_img02}   alt=""/>
    </div>
      : null}

    


    <div className="tabe-title">
        <div>配置する面</div>
    </div>

    {message}
    
      {props.cart ? 
        <table className="specify-table" id="specify">
          {additional_items}
        </table>
      : null}
 


    
  </section>


  )
}

export default ConfirmValiableItem
