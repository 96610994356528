import { useState } from 'react';
import SamplePdfAction from './SamplePdfAction';



function useSamplePdfState() {

  const [pdf, setPdf] = useState(null)
  const [updatedDate, setUpdatedDate] = useState(null)
  const [createdNumber, setCreatedNumber] = useState(null)

  const loadedPdfHandler= (data) => {
    setPdf(data)
    setUpdatedDate(new Date())
  }

  const sendHandler = (data) => {
   console.log(data)
  }

  const statusHandler = (data) => {
    if(data.data.sample_created)
      setCreatedNumber(data.data.sample_created)
  }
  
  const errorHandler = (data) => {
    console.log(data)
  }

  const createPdf = (token, cart_id, sample) => {
    SamplePdfAction.create( token, cart_id, sample, loadedPdfHandler)
  }

  const downloadZip = (token, cart_id) => {
    SamplePdfAction.downloadZip( token, cart_id,  errorHandler)
  }

  const createAllPdf = (token, cart_id) => {
    setCreatedNumber(0)
    SamplePdfAction.createAllSamle(token, cart_id, sendHandler)
  }

  const getCreatingStatus = (token, cart_id) => {
    SamplePdfAction.getStatus(token, cart_id, statusHandler)
  }
  

  return {  pdf:pdf, pdfUpdatedDate:updatedDate, sampleCreatedNumber:createdNumber, createPdf:createPdf, downloadZip:downloadZip, getPdfCreatingStatus:getCreatingStatus, createAllPdf:createAllPdf};
}

export default useSamplePdfState