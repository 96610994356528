import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function FAQApp(props:PropsValue) {

  document.title = "Ｑ＆Ａ｜圧着はがきDM印刷";

  return(

        <div id="page" className="page qa">
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
              <h1 className="stitle-line"><span>Ｑ</span><span>＆</span><span>Ａ</span></h1>
            </section>

            <section className="contents box-link">
              <section className="contentsin box-contents">
                <ul>
                  <li><a href="#qa01">注文前にサンプルをもらえますか？</a></li>
                  <li><a href="#qa02">テンプレートはありますか？</a></li>
                  <li><a href="#qa03">何通から注文できますか？</a></li>
                  <li><a href="#qa04">デザインデータの不備は修正してくれますか？</a></li>
                  <li><a href="#qa05">複数箇所に納品してもらえますか？</a></li>
                  <li><a href="#qa06">配送時間の指定はできますか？</a></li>
                  <li><a href="#qa07">宛名データはどうやって作ればいいですか？</a></li>
                  <li><a href="#qa08">見積書はもらえますか？</a></li>
                  <li><a href="#qa09">発送元を変更できますか？</a></li>
                  <li><a href="#qa10">サイトに載っていない仕様で作成してもらえますか？</a></li>
                  <li><a href="#qa11">お支払い方法は何がありますか？</a></li>
                  <li><a href="#qa12">納期を早めてもらえますか？</a></li>
                  <li><a href="#qa13">会員になったら費用は掛かりますか？</a></li>
                </ul>
              </section>
            </section>

            <section className="contentsin box-contents box-qa">

              <dl id="qa01">
                <dt><span>注文前にサンプルをもらえますか？</span></dt>
                <dd><span>はい、可能です。ご希望のサンプルをお問い合わせください。</span></dd>
              </dl>

              <dl id="qa02">
                <dt><span>テンプレートはありますか？</span></dt>
                <dd><span>デザイン面はillustrator、宛名面はExcelのテンプレートをご用意しております。<br/>
                <a href="/howto">&gt;&gt; テンプレートについて詳しくはこちら</a></span></dd>
              </dl>

              <dl id="qa03">
                <dt><span>何通から注文できますか？</span></dt>
                <dd><span>1,000通から承っております。</span></dd>
              </dl>

              <dl id="qa04">
                <dt><span>デザインデータの不備は修正してくれますか？</span></dt>
                <dd><span>完全データのみ承っております。<br/>
                恐れ入りますが、不備等がある場合はお客様ご自身で修正をお願いいたします。<br/>
                お求めやすい価格でご利用いただくためにご理解いただけますと幸いです。</span></dd>
              </dl>

              <dl id="qa05">
                <dt><span>複数箇所に納品してもらえますか？</span></dt>
                <dd><span>承っております。2箇所目以降、1箇所につき	1,650円かかります。</span></dd>
              </dl>

              <dl id="qa06">
                <dt><span>配送時間の指定はできますか？</span></dt>
                <dd><span>はい、可能です。備考欄よりご希望の配送時間をお知らせください。</span></dd>
              </dl>

              <dl id="qa07">
                <dt><span>宛名データはどうやって作ればいいですか？</span></dt>
                <dd><span>テンプレートページより、専用のExcelをダウンロードして作成をお願いいたします。<br/>
                <a href="/howto/#download-csv">&gt;&gt; データ作成方法について詳しくはこちら</a></span></dd>
              </dl>

              <dl id="qa08">
                <dt><span>見積書はもらえますか？</span></dt>
                <dd><span>承っております。<br/>
                お問い合わせページよりご連絡いただけましたら発行いたします。<br/>
                <a href="/inquiry">&gt;&gt; お問い合わせページはこちら</a></span></dd>
              </dl>

              <dl id="qa09">
                <dt><span>発送元を変更できますか？</span></dt>
                <dd><span>承っております。ご注文の際に、備考欄に発送元を変更する旨をご記載ください。</span></dd>
              </dl>

              <dl id="qa10">
                <dt><span>サイトに載っていない仕様で作成してもらえますか？</span></dt>
                <dd><span>可能な場合もございます。ご注文可能な仕様かどうか、一度お問い合わせください。<br/>
                <a href="/inquiry">&gt;&gt; お問い合わせページはこちら</a></span></dd>
              </dl>

              <dl id="qa11">
                <dt><span>お支払い方法は何がありますか？</span></dt>
                <dd><span>クレジットカード決済、代金引換からお選びいただけます。</span></dd>
              </dl>

              <dl id="qa12">
                <dt><span>納期を早めてもらえますか？</span></dt>
                <dd><span>ご注文時期や仕様によっては、可能な場合もございます。お問い合わせください。<br/>
                ※別途特急料金がかかる場合がございます。</span></dd>
              </dl>

              <dl id="qa13">
                <dt><span>会員になったら費用は掛かりますか？</span></dt>
                <dd><span>無料でご登録いただけます。</span></dd>
              </dl>


            </section>


          </article>
          <Footer />
        </div>


  )
}

export default FAQApp
