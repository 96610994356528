import React, { } from 'react'

function OrderApp(props:PropsValue) {
 
  return(

       
    <div id="help">
      <header id="header">
	<h1>ご注文フォームについて</h1>
</header>

<article id="contents">
	<section className="contentsin">
		<section className="contents-box">
			<div className="title-box">
				<h2>仕様（※送料との兼ね合いも説明）</h2>
			</div>
			<div className="comment-box">
				<p>印刷の折りタイプは、V型はがきとZ型はがきの２パターンをご用意しております。<br/>
				※はがきの厚み（折りの違い）により、１箱に詰め込めるはがきの枚数が変り配送料が異なってきます。<br/>
				配送料の詳細は、<a href="/delivery#postage" target="_blank">料金ページ</a>をご確認ください。</p>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>用紙</h2>
			</div>
			<div className="comment-box">
				<p>マットコート紙をご用意しております。</p>
			</div>
		</section>

		<section className="contents-box">
			<div className="title-box">
				<h2>用紙の厚み</h2>
			</div>
			<div className="comment-box">
				<p>厚みは、90kg・110kg・135kgと3パターンをご用意しております。<br/>
				※厚みにより配送料が異なります。配送料の詳細は、<a href="/delivery#postage" target="_blank">料金ページ</a>をご確認ください。</p>
			</div>
		</section>

		<section className="contents-box">
			<div className="title-box">
				<h2>加工方法</h2>
			</div>
			<div className="comment-box">
				<p>「ずらし加工」を用います。</p>
				<div className="img">
					<img src="/images/help/zurashi.jpg" alt="ずらし加工の図"/>
				</div>
			</div>
		</section>
		<section className="contents-box">
			<div className="title-box">
				<h2>印刷プラン</h2>
			</div>
			<div className="comment-box">
				<p>３つの印刷プランからお選びいただけます。</p>
				<ul>
					<li><strong>宛名あり</strong><br/>背景データとCSVに記載いただいた宛名のみを印刷します。</li>
					<li><strong>【変更後】宛名あり+可変項目印刷</strong><br/>背景データとCSVに記載いただいた宛名と可変項目（最大3つ）を印刷します。</li>
					<li><strong>宛名なし</strong><br/>背景データのみを印刷します。</li>
				</ul>
			</div>
		</section>

		<section className="contents-box">
			<div className="title-box">
				<h2>枚数</h2>
			</div>
			<div className="comment-box">
				<ul>
					<li><strong>宛名あり、宛名あり+可変項目印刷</strong><br/>
					最大２万まで１枚単位で印刷できます。</li>
					<li><strong>宛名なし</strong><br/>
					最小1000枚から最大２万まで、100枚単位で印刷できます。</li>
				</ul>
			</div>
		</section>

	</section>
</article>
    </div>


  )
}

export default OrderApp
