import React, {  useEffect } from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'
import useRegisterState from './useRegisterState'


function RegisterCompleteApp(props:PropsValue) {
  document.title = "登録完了｜DM圧着はがきDM印刷";

  const {account, loadAccountInfo} = useRegisterState()


  useEffect(() => {
    loadAccountInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    
  },[account]);


  const  gotoStep =() => {  
    window.open("/order/step1?type=new", '_blank')
  }   

  return(
      <div id="page" className="member temporary-registration completion"> 
        <Header  noAuth={true}/>
        <Menu />
        <article id="contents">
        <section className="topcomment">
          <h1>登録完了</h1>
          <p>仮登録が完了し、ご登録のメールアドレスに仮登録のお知らせをお送りしました。<br/>
          印刷物のご入稿、会員ページをご利用いただけます。</p>
        </section>

        <section className="s-contentsin2">

          <div className="buttonbox pt1">
            <div className="button or">
              <a href="/mypage">会員ページへ</a>
            </div>
            <div className="button or" onClick={() => gotoStep()}>
              <div>印刷入稿へ</div>
            </div>
          </div>

        </section>

        </article>
        <Footer />
      </div>


  )
}

export default RegisterCompleteApp


