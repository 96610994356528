import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function HowtoOrderApp(props:PropsValue) {


  document.title = "入稿の流れ｜圧着はがきDM印刷";

  return(

        <div id="page" className="page flow" >
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
            <h1 className="stitle-line">入稿の流れ</h1>
            <p>印刷物を入稿するまでの流れをご説明いたします。<br />
            「宛名なし」、「宛名あり」、「宛名あり+可変項目印刷」の印刷で入稿ステップは異なりますのでご注意ください。</p>
          </section>




          <section className="contents title-box">
            <div className="contentsin">
              <h2>入稿データのご用意</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
                <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
              </ul>
            </div>
          </section>
	        <section className="contents" id="box-link">

            <p className="top-com contentsin">当サイトでは、2種類の折（V型・Z型）と3種類の印刷方法をご用意しております。<br/>
            折と印刷方法に合わせて入稿データをご用意ください。</p>

            <div className="contentsin column col3">
              <div className="box" id="printing01">
                <div className="boxin">
                  <div className="title-box">
                    <h2>
                      <span>DMに最適</span>
                      <div>宛名あり印刷</div>
                    </h2>
                    <p>宛名面に宛名を配置した圧着ハガキ印刷。宛名の位置は自由に調整いただけます。</p>
                  </div>
                  <div className="box-img">
                    <img src="/images/common/img_plan01.png" alt="宛名あり印刷" />
                  </div>
                </div>
                <div className="button">
                  <a href="/howto"><span>入稿方法</span></a>
                </div>
              </div>

              <div className="box" id="printing02">
                <div className="boxin">
                  <div className="title-box">
                    <h2>
                      <span>可変QRコードも可能</span>
                      <div>宛名あり+可変項目印刷</div>
                    </h2>
                    <p>宛名と可変項目を配置した圧着ハガキ印刷です。宛名、可変項目の位置は自由に調整いただけます。</p>
                  </div>
                  <div className="box-img">
                    <img src="/images/common/img_plan02.png" alt="宛名あり+可変項目印刷" />
                  </div>
                </div>
                <div className="button">
                  <a href="/howto"><span>入稿方法</span></a>
                </div>
              </div>

              <div className="box" id="printing03">
                <div className="boxin">
                  <div className="title-box">
                    <h2>
                      <span>宛名印字はお客様で</span>
                      <div>宛名なし印刷</div>
                    </h2>
                    <p>宛名はお客様で印刷いただく、シンプルな圧着印刷のみのサービスです。</p>
                  </div>
                  <div className="box-img">
                    <img src="/images/common/img_plan03.png" alt="宛名なし印刷" />
                  </div>
                </div>
                <div className="button">
                  <a href="#plan03"><span>入稿方法</span></a>
                </div>
              </div>
            </div>

            <p className="link-com contentsin">データの準備方法は、<br className="brsp" /><a href="/howto">「入稿方法ページ」</a>をご覧くいださい。</p>

          </section>

          <div className="arrow"> </div>

          <section className="contents title-box" id="registration">
            <div className="contentsin">
              <h2>会員登録・仮会員登録</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
                <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
              </ul>
            </div>
          </section>

          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img01.jpg" alt="" />
              </div>

              <div className="r comment-box">
                <div className="comment-boxin">
                  <p>入稿いただくには、会員登録または仮会員登録が必要です。</p>
                </div>

                <div className="comment-boxin">
                  <h3>仮会員登録</h3>
                  <p>印刷入稿ステップへ進むには会員登録が必要です。簡易的な仮会員登録をしてからお進みいただきます。</p>
                </div>

                <div className="comment-boxin">
                  <h3>会員登録</h3>
                  <p>マイページの「会員情報変更」から、正式な会員登録を行っていただけます。<br/>
                                        会員ページでは、注文履歴をご確認いただけます。</p>
                </div>
              </div>
            </div>
          </section>

	        <div className="arrow"><p>印刷入稿ステップへ</p></div>

          <section className="contents title-box" id="step1">
            <div className="contentsin">
              <h2 className="step">印刷内容を選択・料金確認</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
                <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
              </ul>
            </div>
          </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img02.jpg" alt="" />
              </div>

              <div className="r comment-box">


                <div className="comment-boxin">
                  <p>ハガキの仕様、プランを確認、選択してください。<br />
                  印刷プランで、「宛名あり」「宛名あり+可変項目印刷」を選択された場合は、CSVのアップロードを行ってください。<br/>
                  送料をのぞいた印刷金額が表示されますので、ご確認の上、次のステップへお進みください。<br/>
                  ※送料を含めた金額は、注文内容確認ページでご確認いただけます。</p>
                </div>

                <div className="comment-boxin">
                  <h3>選べるプラン</h3>
                  <ul className="li-dot">
                    <li>
                      <strong>宛名あり</strong><br/>
                      CSVで作成した宛名を配置し印刷します。<br/>
                      宛名の位置は、印刷可能な範囲で自由に配置いただけます。
                    </li>
                    <li>
                      <strong>宛名あり+可変項目印刷</strong><br/>
                      宛名以外に、自由に入力できる可変項目を最大３つまで配置し、宛名と同じく位置を印刷可能な範囲で自由に配置いただけます。<br/>
                      可変の項目の１つをQRコードに変換することも可能です。
                    </li>
                    <li>
                      <strong>宛名なし</strong><br/>
                      ハガキの宛名面・中面のデザインのみを印刷します。
                    </li>
                  </ul>
                </div>

                <div className="comment-boxin">
                  <h3>印刷できる枚数、単位</h3>
                  <ul className="li-dot">
                    <li><strong>宛名あり / 宛名あり+可変項目印刷</strong><br/>1000枚～20000枚まで　1枚単位で印刷可能</li>
                    <li><strong>宛名なし</strong><br/>1000枚～20000枚まで　100枚単位で印刷可能</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <div className="arrow"> </div>

          <section className="contents title-box" id="step2">
            <div className="contentsin">
              <h2 className="step">アップロードした可変データの確認</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
              </ul>
            </div>
          </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img03.jpg" alt="" />
              </div>

              <div className="r comment-box">
                <div className="comment-boxin">
                  <p>CSVでアップロードした項目が、うまく連携しているか確認してください。</p>
                </div>

                <div className="comment-boxin">
                  <p><strong>⦿「宛名あり+可変項目印刷」をお選びの方</strong><br/>
                  可変の項目を配置する印刷面を選んでください。<br/>
                  可変の項目をQRコードに変更したい場合は、変更したい項目を１つのみお選びください。<br/>
                  アップロードしたCSVをご確認の上、「レイアウト編集」へお進みください。</p>
                </div>
              </div>
            </div>
          </section>

          <div className="arrow"> </div>

          <section className="contents title-box" id="step3">
            <div className="contentsin">
              <h2 className="step">レイアウトを編集してください</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
                <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
              </ul>
            </div>
          </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img04.jpg" alt="" />
              </div>

              <div className="r comment-box">
                <div className="comment-boxin">
                  <p>背景デザイン（PDF）の宛名面、中面をアップロードしてください。<br/>
                  ※PDFがRGBの場合は、CMYKに変換されます。色味が変わる場合がございますのでご了承ください。<br/>
                  ※アップロードする背景データは、弊社指定のイラストレータのデータをPDFに変換したものをご使用ください。</p>
                </div>

                <div className="comment-boxin">
                  <h3>「宛名無し」をお選びの方</h3>
                  <p>背景デザイン（PDF）のアップロードが完了しましたら、「レイアウトイメージ確認」へお進みください。</p>
                </div>

                <div className="comment-boxin">
                  <h3>「宛名あり」「宛名あり+可変項目印刷」をお選びの方</h3>
                  <p>背景デザイン（PDF）のアップロードが完了しましたら、まずは「レイアウト調整」へ進み、宛名などの位置の調整、確認を行ってください。<br/>
                  その後、「レイアウトイメージ確認」へお進みください。</p>
                </div>
              </div>
            </div>
          </section>

          <div className="arrow"> </div>

          <section className="contents title-box" id="step4">
            <div className="contentsin">
              <h2 className="step">レイアウトの調整をしてください</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
              </ul>
            </div>
          </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img05.jpg" alt="" />
              </div>

              <div className="r comment-box">


                  <div className="comment-boxin">
                    <p>宛名の位置や文字サイズなどを変更できます。
                    「宛名あり+可変項目印刷」をお選びの方は、可変ボックス（最大３つ）の位置や文字サイズなども変更できます。
                    印刷可能な範囲で編集を行ってください。
                    編集が完了しましたら「レイアウト編集」へ戻り、「レイアウトイメージ確認」へお進みください。</p>
                    <div className="atten-box">
                      <p><strong>【注意】</strong><br/>
                      宛名の編集画面には、白フチと通し番号の印字面が青色で表示されています。<br/>
                      その色に被りますと印刷できないなどのトラブルの原因となりますので、必ず被らないように宛名や可変ボックスを配置してください。</p>
                    </div>
                  </div>

                <div className="comment-boxin">
                  <h3>最初に表示される宛名について</h3>
                  <p>「レイアウト調整」で一番最初に表示される宛名は、各項目の最大文字列のものを配置したサンプルページとなります。<br/>
                  一番多い文字数の項目を配置することで、ボックスの重なりを防ぎながら編集することが可能となります。<br/>
                  ページを切り替えることで、各宛名ごとのレイアウトを確認することもできます。</p>
                </div>

                <div className="comment-boxin">
                  <h3>各ボックスの編集できる内容</h3>
                  <ul className="li-dot">
                    <li>ボックスの位置、大きさ</li>
                    <li>文字のスタイル、位置、サイズ、行の高さ、文字間隔、文字の色、ボックスの背景</li>
                  </ul>
                </div>

              </div>
            </div>
          </section>

      	<div className="arrow"> </div>

        <section className="contents title-box" id="step5">
          <div className="contentsin">
            <h2 className="step">レイアウトイメージを確認してください</h2>
            <ul>
              <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
              <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
              <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
            </ul>
          </div>
        </section>


        <section className="contents step-box back-line">
          <div className="contentsin box">
            <div className="l img-box">
              <img src="/images/flow/img06.jpg" alt="" />
            </div>
            <div className="r comment-box">

              <div className="comment-boxin">
                <h3>「宛名無し」をお選びの方</h3>
                <p>レイアウトイメージをご確認ください。<br/>
                レイアウトをご確認いただき問題なければ、「注文内容」へお進みください。</p>
              </div>

              <div className="comment-boxin">
                <h3>「宛名あり」「宛名あり+可変項目印刷」をお選びの方</h3>
                <p>レイアウトイメージをご確認ください。<br/>
                サンプルページ（各項目の最大文字列のものを配置）、または通し番号で指定したページをPDFでダウンロードしてご確認いただくことも可能です。<br/>
                レイアウトをご確認いただき問題なければ、「最終印刷へ」へお進みください。</p>
              </div>

            </div>
          </div>
        </section>

        <div className="arrow"> </div>

        <section className="contents title-box" id="step6">
          <div className="contentsin">
            <h2 className="step">最終印刷データの確認</h2>
            <ul>
              <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
              <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
            </ul>
          </div>
        </section>
        <section className="contents step-box back-line">
          <div className="contentsin box">
            <div className="l img-box">
              <img src="/images/flow/img07.jpg" alt="" />
            </div>

            <div className="r comment-box">
              <div className="comment-boxin">
                <p>入稿するにあたり、最終の印刷データのご確認を行ってください。<br/>
                確認用データは、縮小してPDFに生成されます。<br/>
                配置や文字などの最終のご確認をお願いいたします。<br/>
                生成したPDFをダウンロードし、確認を終えられましたら、「注文内容へ」へお進みください。</p>
              </div>

              <div className="comment-boxin">
                <h3>生成時間について</h3>
                <p>ページ数、背景データの容量、サーバの混み具合により生成時間は異なります。<br/>
                時間帯によっては、数十分お待ちいただく場合もございます。<br/>
                データが生成できましたら、ご登録いただきましたメールに「生成完了」のメールをお送りいたしますので、そのままお待ちいただくか、画面を閉じて、メールの受信をお待ちください。</p>
              </div>

              <div className="comment-boxin">
                <div className="atten-box">
                  <p><strong>【注意】生成データの保存について</strong><br/>
                  生成データは、２４時間以内にご確認ください。<br/>
                  ２４時間を超えた場合は、データが削除される場合もございますので、ご注意ください。</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="arrow"> </div>

        <section className="contents title-box" id="step7">
          <div className="contentsin">
            <h2 className="step">注文内容確認</h2>
            <ul>
              <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
              <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
              <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
            </ul>
          </div>
        </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img08.jpg" alt="" />
              </div>

              <div className="r comment-box">
                <div className="comment-boxin">
                  <p>データの入稿を行います。<br/>
                  配送先、決済方法がお選びいただけましたら、「最終確認」へお進みください。</p>
                </div>

                <div className="comment-boxin">
                  <div className="atten-box">
                    <p><strong>⦿ 会員登録をお済ませでない方（仮会員の方）</strong><br/>
                    入稿ステップに進むには、会員登録が必要です。<br/>
                    登録を済ませてから、入稿ステップへお進みください。</p>

                    <p><strong>⦿ 会員登録を行なっている場合</strong><br/>
                    選択いただいた仕様やプラン、枚数をご確認ください。</p>
                  </div>
                </div>

                <div className="comment-boxin">
                  <h3>配送先の選択について</h3>
                  <p className="bm-none">配送先は次の中からお選びいただけます。</p>
                  <ul className="li-dot">
                    <li>会員情報の住所への配送</li>
                    <li>会員情報以外の配送（最大６つまでご登録可能）</li>
                  </ul>
                </div>

                <div className="comment-boxin">
                  <h3>送料を含んだ合計金額の表示</h3>
                  <p>配送先で選んだ都道府県に合わせ、送料を表示します。</p>
                </div>

                <div className="comment-boxin">
                  <h3>決済方法のご選択</h3>
                  <p className="bm-none">決済方法は、次のものからご選択いただけます。</p>
                  <ul className="li-dot">
                    <li>代金引換　※30万円（税、送料含む）までの金額に対応</li>
                    <li>クレジットカード（ヤマトEC決済を使用）</li>
                  </ul>
                  <div className="img card">
                    <img src="/images/flow/img_card.png" alt="クレジットカード" />
                  </div>
                </div>



              </div>
            </div>
          </section>

          <div className="arrow"> </div>

          <section className="contents title-box" id="step8">
            <div className="contentsin">
              <h2 className="step">最終確認</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
                <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
              </ul>
            </div>
          </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img09.jpg" alt="" />
              </div>

              <div className="r comment-box">
                <div className="comment-boxin">
                  <p>各項目の最終のご確認を行なってください。</p>
                </div>

                <div className="comment-boxin">
                  <h3>クレジットカード決済をお選びの方</h3>
                  <p>ヤマトの決済画面で決済を行なってください。<br/>
                  決済が完了し、入稿完了となります。</p>
                  <div className="atten-box">
                    <p><strong>【注意】</strong><br/>
                    ２４時間たっても決済が完了されていない場合は、入稿データを削除いたしますのでご注意ください。</p>
                  </div>
                </div>

                <div className="comment-boxin">
                  <h3>代金引換をご選択のお客様</h3>
                  <p>以上で、入稿完了となります。</p>
                </div>

              </div>
            </div>
          </section>

          <div className="arrow"> </div>

          <section className="contents title-box" id="completed">
            <div className="contentsin">
              <h2>お支払い・入稿完了</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
                <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
              </ul>
            </div>
          </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">
              <div className="l img-box">
                <img src="/images/flow/img10.jpg" alt="" />
              </div>

              <div className="r comment-box">
                <div className="comment-boxin">
                  <h3>代金引換をご選択の方</h3>
                  <p>入稿完了です。</p>

                  <h3>クレジットカード払いをご選択の方</h3>
                  <p>クロネコWEBコレクトサービスへ移動し、クレジットカードにて料金をお支払いください。</p>
                </div>
              </div>
            </div>
          </section>

          <div className="arrow"> </div>

          <section className="contents title-box" id="shipping">
            <div className="contentsin">
              <h2>商品発送</h2>
              <ul>
                <li><img src="/images/flow/icon_plan01.png" alt="宛名あり印刷" /></li>
                <li><img src="/images/flow/icon_plan02.png" alt="宛名あり+可変項目印刷" /></li>
                <li><img src="/images/flow/icon_plan03.png" alt="宛名なし印刷" /></li>
              </ul>
            </div>
          </section>
          <section className="contents step-box back-line">
            <div className="contentsin box">

              <div className="comment-box wide">
                <div className="comment-boxin">
                  <p>以上で入稿完了となります。<br/>
                  入稿後は、弊社にて入稿データを確認し、問題がないかをチェックいたします。<br/>
                  チェック後、印刷を5営業日以内に行い発送いたします。<br/>
                  発送などの進捗状況はマイページからでもご確認いただけます。<br/>
                  データに関するご質問がございましたら、フォームよりお問合せください。</p>

                  <div className="boxin">
                    <ul>
                      <li>
                        <div className="button">
                          <a href="/inquiry"><span>お問合せフォームへ</span></a>
                        </div>
                      </li>
                      <li>
                        <div className="button">
                          <a href="/order-history"><span>マイページへ</span></a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </section>



          </article>
          <Footer />
        </div>


  )
}

export default HowtoOrderApp
