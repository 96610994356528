import React, { } from 'react'
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import Utile from '../utiles/Utile';
import RequestValue from '../order/RequestValue';
import usePrefectreState from '../prefectre/usePrefectreState';
function HistoryItem(props) {


  const {getPrefectureName} = usePrefectreState()

  if(!props.item) {
    return (<div></div>)
  } else {
    if(props.item.deleted_by !== null){
      return(
        <div className="box delete">
        <div className="boxin">
          <p><strong>{props.item.filename ? props.item.filename : props.item.data_name} {format(Date.parse(props.item.created_at), 'yyyyMd', {locale: ja})}</strong>　は、管理者によりデータは削除されました</p>
        </div>
	    	</div>
      )
    }

    return (
      <div className="box" >
      
        <div className="boxin">
          <dl className="table">
            <dt>注文番号</dt>
            <dd>{props.item.order_no}</dd>
          </dl>
          <dl className="table">
            <dt>入稿日</dt>
            <dd>{format(Date.parse(props.item.created_at), 'yyyy年M月d日', {locale: ja})}</dd>
          </dl>
          <dl className="table">
            <dt>データ名</dt>
            <dd>{props.item.filename ? props.item.filename : props.item.data_name}</dd>
          </dl>
          <dl className="table status">
            <dt>現在のステータス</dt>
            <dd>{props.item.status}</dd>
          </dl>

        
          <dl className="table content">
            <dt>入稿内容</dt>
            <dd>
              <dl className="table">
                <dt>仕様</dt>
                <dd>{props.item.specification}</dd>
              </dl>
              <dl className="table">
                <dt>用紙</dt>
                <dd>{props.item.paper}</dd>
              </dl>
              <dl className="table">
                <dt>用紙の厚み</dt>
                <dd>{props.item.thickness}</dd>
              </dl>
              <dl className="table">
                <dt>圧着方法</dt>
                <dd>{props.item.process_type}</dd>
              </dl>
              <dl className="table">
                <dt>加工方法</dt>
                <dd>{props.item.processing}</dd>
              </dl>
              <dl className="table">
                <dt>印刷プラン</dt>
                <dd>{
                props.item.plan === RequestValue.PLAN_ATENA_NAKA ? "宛名あり＋可変項目印刷"
                : props.item.plan}</dd>
              </dl>
              <dl className="table">
                <dt>印刷枚数</dt>
                <dd>{props.item.sheet ? props.item.sheet.toLocaleString(): null}枚</dd>
              </dl>
            </dd>
          </dl>

          <dl className="table">
          <dt>価格</dt>
          <dd>{props.item.price.toLocaleString()}円</dd>
        </dl>

        <dl className="table">
          <dt>送料</dt>
          <dd>{props.item.dilivery_price.toLocaleString()}円</dd>
        </dl>


        <dl className="table">
          <dt>決済方法</dt>
          <dd>{props.item.payment_method}</dd>
        </dl>
        <dl className="table">
          <dt>備考</dt>
          <dd>{props.item.memo}</dd>
        </dl>
        <dl className="table">
          <dt>配送先</dt>
          <dd>{}
            〒 {props.item.shippings ?  props.item.shippings.post1 : null }-{props.item.shippings ?  props.item.shippings.post2 : null } <br/>
            {props.item.shippings ?  getPrefectureName(props.item.shippings.prefecture_id) : null }{props.item.shippings ?  props.item.shippings.address : null } {props.item.shippings ?  props.item.shippings.address_add : null }<br/>
            {props.item.shippings ?  props.item.shippings.company_name : null } <br/>
            {props.item.shippings ?  props.item.shippings.name : null } 宛<br/>
            TEL : {props.item.shippings ?  props.item.shippings.tel : null } 
          </dd>
        </dl>

        <dl className="table">
          <dt>管理者からのお知らせ</dt>
          <dd>{props.item.message ? Utile.nl2br(props.item.message) : null}</dd>
		    </dl>


         
        </div>
      </div>
    )
  }
}

export default HistoryItem



