import React, {  useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../../header/HeaderForStep'
import cookie from 'react-cookies'
import RequestValue from '../RequestValue';
import useCartState from '../cart/useCartState'
import useAuthState from '../../auth/useAuthState'

import step_img from "../../images/step/step-step6.png"
import useSamplePdfState from '../edit_layout/samplePdf/useSamplePdfState'



function GeneratePdfApp(props:PropsValue) {
  document.title = "最終印刷データの確認｜圧着はがきDM印刷";

  const {id} = useParams()
  var _is_loading = false
  const [is_download , setDownloaded] = useState(false)
  const [sample_img , setStepImage] = useState(false)
  const [intval, setIntval] = useState(null)
  const {token, auth} = useAuthState()
  const {cart, cart_error, current_cart_id, getCart, getCurrentCartId} = useCartState()
  const {sampleCreatedNumber,downloadZip,createAllPdf, getPdfCreatingStatus} = useSamplePdfState()

  useEffect(() => {
    auth()
    if(id === undefined || id === null){
      getCurrentCartId()
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(cart && (id === undefined || id === null) ){
      setStepImage(cart.specification === RequestValue.SPECIFICATION_V ? "sv": "sz")
      getPdfCreatingStatus(token, cart.id)
      cookie.save('current_cart_id', cart.id, { path: '/' })
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cart]);

  useEffect(() => {
    getCurrentCart(current_cart_id)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);



  useEffect(() => {
    if(intval === null && sampleCreatedNumber > 0){
      setIntval(setInterval(checkStatus, 10000))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sampleCreatedNumber]);


  useEffect(() => {
    if(token &&   id){
      getCart(token, id)
    }else{
      getCurrentCart()
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {

  },[cart ]);

  const getCurrentCart = () =>{
    if(token && current_cart_id  && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
      getPdfCreatingStatus(token, current_cart_id)
    }
  }

  const generate = () => {
    //setIsGenerating(true)
    createAllPdf(token, current_cart_id)
    setIntval(setInterval(checkStatus, 10000))
  }
  const download = () => {
    downloadZip(token, current_cart_id ? current_cart_id : cart.id)
    setDownloaded(true)
  }
  const checkStatus = () => {
    //console.log("check")
    getPdfCreatingStatus(token, current_cart_id)
  }

  const moveTo = (step) => {
    if(step)
      window.location.href = "/order/" + step
  }



  return(

        <div id="step" className="step step6">
          <Header token={token} />
          {
            !cart && !cart_error? <div></div> :
            !cart ?
            <article id="contents">

            <section className="s-contentsin2 topcomment">
              <h1>現在、このアドレスはご利用頂けません</h1>

              <div className="box">

                  <p className="ct">ご入稿をご希望の方は、トップページから入稿ステップにお進みください。</p>

                  <div className="button next mt-20">
                    <a href="/"><span>トップページへ戻る</span></a>
                  </div>

              </div>
            </section>


          </article>


         :
          <article id="contents">


            <section className="contentsin now-step">
                <img src={step_img} alt="最終印刷データの確認" />
            </section>

            <section className="s-contentsin2 topcomment">
              <h1>最終印刷データの確認</h1>
              <div className="box">
                <div className={"boxin " + sample_img}>
                  <div className="text-box">
                    <p>ご入稿いただいた印刷データの最終確認をしていただきます。<br/>
                    全てのページデータを生成するため、少々お時間をいただく場合がございます。</p>
                    <p><strong>【お時間の目安】</strong><br/>
                    1,000枚・・・約30秒<br/>
                    10,000枚・・・約5分<br/>
                    ※サーバの状況により生成の時間は異なります。</p>
                  </div>
                  <p><strong>■生成が完了したら</strong><br/>
                  生成したPDFをダウンロードしてご確認ください。<br/>
                  また、生成完了後にご登録いただきましたメールアドレス宛にご連絡いたします。<br/>
                  メールに記載されたURLから再度アクセスして、生成したPDFをダウンロードしてご確認いただく事も可能です。<br/>
                  生成したPDFの宛名に問題がなければ、次のステップ「注文内容確認へ」へお進みください。</p>
                </div>




              {
                cart && ((sampleCreatedNumber ) >= cart.sheet || cart.sample_created >= cart.sheet)?

                <div class="input-button-download">
                  <div class="buttonbox">
                    <span><input type='button' name='download' value='生成したPDFをダウンロード' id='download' checked   onClick={() => download()} /><label for='download'>生成したPDFをダウンロード</label></span>
                  </div>
                </div>



                :sampleCreatedNumber === null ?


                <div class="input-button-generation">
                  <div class="buttonbox">
                    <span><input type='button' name='generation' value='宛名確認用のデータを生成' id='generation' checked  onClick={() => generate() }/><label for='generation'>宛名確認用のデータを生成</label></span>
                  </div>
                </div>



                :
                <div id="spin" className="col text-center mt-20">
                  <button className="btn btn-outline-dark p-3 fw-bold fs-3 text-dark" type="button" disabled>
                      <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>　
                      サンプルファイル作成中。しばらくお待ちください...
                  </button>
                </div>


                }

            </div>
            </section>
          </article>
          }
          <footer id="footer-step">
              <div className="s-contentsin">
                  <div className="l">
                      <div className="button forward">
                        <div id="nextPage" onClick={() => moveTo("step3")}>
                              <span>レイアウト編集へ</span>

                        </div>
                    </div>
                  </div>

                  <div className="r">
                    {
                      is_download
                        ?  <div className="button next" onClick={() => moveTo("step7")} > <div><span>注文内容確認へ</span></div></div>
                        :  <div className="button off next" > <div><span>注文内容確認へ</span></div></div>
                    }
                  </div>
              </div>
          </footer>

        </div>


  )
}

export default GeneratePdfApp
/** &nbsp;&nbsp;&nbsp;{sampleCreatedNumber} / {cart ? cart.sheet : null} 完了。 **/
/**  &nbsp;&nbsp;&nbsp;{sampleCreatedNumber + 1000} / {cart ? cart.sheet : null} を作成中。しばらくお待ちください...  */
