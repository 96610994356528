import React, {  useEffect } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'
import UserMenu from '../menu/UserMenu'
import useProfileState from './useProfileState'
import usePrefectreState from '../prefectre/usePrefectreState'

function ProfileConfirmApp(props:PropsValue) {

  document.title = "会員情報変更 登録内容のご確認｜DM圧着はがきDM印刷";

  const {token, auth} = useAuthState()
  const {profile, profileError, createResult, loadProfiletInfo,removeProfileInfo, updateProfile} = useProfileState()


  const {getPrefectureName} = usePrefectreState()
  useEffect(() => {
    auth()
    loadProfiletInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    
  },[profile]);
  
  useEffect(() => {
    if(profileError){
      window.location.href = "/mypage"
    }
  },[profileError]);

  useEffect(() => {
    if(createResult){
      removeProfileInfo()
      window.location.href = "/mypage"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[createResult]);


  const updateItem = () => {
    updateProfile(token, profile)
  }


  const goback = () => {
    window.location.href = "/mypage"
  }

  return(
    profile ?
        <div id="page" className="member register check"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
            <UserMenu />
            <section className="s-contentsin2 topcomment1">
		<h1 className="stitle">会員情報変更</h1>
		<h2 className="stitle3">変更内容のご確認</h2>
		<p>ご変更の内容をご確認ください。問題なければ、「変更する」ボタンを押してください。</p>
	</section>

	<form id="editPost" >

		<section className="s-contentsin2 delivery-list">

			<div className="box">
				<table className="form-table address-table">
					<tr>
						<th>
							<span className="thname">ID（メールアドレス）</span>
						</th>
						<td>
							{ profile.email }
						</td>
					</tr>
					
				</table>
			</div>

			<div className="box">
				<table className="form-table address-table">
					<caption>基本情報</caption>
					<tr>
						<th>
							<span className="thname">会社名</span>
						</th>
						<td>
							{profile.company_name}
						</td>
					</tr>
					<tr>
						<th>
							<span className="thname">お名前</span>
						</th>
						<td>
							{profile.name}
						</td>
					</tr>
					<tr>
						<th>
							<span className="thname">お名前（フリガナ）</span>
						</th>
						<td>
						  {profile.name_kana}
						</td>
					</tr>
					<tr>
						<th>
							<span className="thname">郵便番号</span>
						</th>
						<td>
							{profile.post1}-{profile.post2}
						</td>
					</tr>
					<tr>
						<th>
							<span className="thname">都道府県</span>
						</th>
						<td>
              {getPrefectureName(profile.prefecture_id)}
						</td>
					</tr>
					<tr>
						<th>
							<span className="thname ls10">住所</span>
						</th>
						<td>
              {profile.address}
						</td>
					</tr>
					<tr>
						<th>
							<span className="thname">マンション名など</span>
						</th>
						<td>
            {profile.address_add}
						
						</td>
					</tr>
					<tr>
						<th>
							<span className="thname">電話番号</span>
						</th>
						<td>
            {profile.tel}
					
						</td>
					</tr>
				</table>
				<div className="buttonbox pt1">
					<div className="button gr">
						<div onClick={goback}>修正する</div>
					</div>
					<div className="button or">
            <div onClick={updateItem}>登録する</div>
					</div>
				</div>
			</div>

		</section>
	  </form>
            </article>
          <Footer />
        </div>
  
    : null
  )
}

export default ProfileConfirmApp
