import React, { useState, useEffect } from 'react'

function EditorTopMenu(props:PropsValue) {
  
 const [cpage, setCpage] = useState(null)
  const changePageHandler = (e) => {
    const target = e.target
    const cn = Number(target.value)
    if(cn && cn <= props.cart.csv.length && cn > 0)
      props.setCurrentPage(target.value)
  }


  useEffect(() => {

    if(props.current_page){
      setCpage(props.current_page)
    }else{
      setCpage("-")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.current_page]);

  const firstPageBtnHandler =(e) => {
    props.setCurrentPage(1)
  }
  const lastPageBtnHandler =(e) => {
    props.setCurrentPage(props.cart.csv.length)
  }

  const nextPageBtnHandler =(e) => {
    if(props.current_page <= props.cart.csv.length - 1)
      props.setCurrentPage(props.current_page + 1)
  }

  const previousPageBtnHandler =(e) => {
    if(props.current_page > 1)
      props.setCurrentPage(props.current_page - 1)
  }

  const samplePageBtnHandler = () => {
    props.setCurrentPage(null)
  }

  return(
    
    <section className="contentsin control-panelin">
  
      <div className="buttonbox surface">
        <div onClick={() => props.moveToFronPage()}>
          <input type='radio' name='surface' checked={props.is_frontpage ? true: false}  value='宛名面表示' id="surface-display"  /><label htmlFor="surface-display" >宛名面表示</label>
				</div>

				<div   onClick={() => props.moveToBackPage()} >
          <input type='radio' name='surface'  checked={props.is_backpage ? true: false}   /><label htmlFor="surface-medium" >中面表示</label>
				</div>
			</div>

      <div className="buttonbox pagination">

          <div className="button" id="btn-first" onClick={firstPageBtnHandler}>
              <span><input type='button' name='surface-display' value='先頭へ' id='page-top' /><label htmlFor='surface-top' >&lt;&lt; 先頭へ</label></span>
          </div>
          <div className="button" id="btn-previous" onClick={previousPageBtnHandler}>
              <span><input type='button' name='surface-display' value='前ページへ' id='page-before' /><label htmlFor='surface-before'>&lt; 前ページへ</label></span>
          </div>
          <div className="page-num">
              <input type="page-num" id="page-num" name="number" value={cpage} onChange={changePageHandler}  />&nbsp;
                  ページ目
          </div>

          <div className={!props.current_page ? "button selected sample" : "button sample" } >
            <span><button name='sample-mode' value='サンプルモード' id='sample-mode' onClick={samplePageBtnHandler} /><label for='sample-mode'><span>サンプル<br />モード</span></label></span>
          </div>

          
          <div className="button" id="btn-next" onClick={nextPageBtnHandler}>
            <span><input type='button' name='surface-display' value='次ページへ' id='page-next' /><label htmlFor='surface-next'>次ページへ &gt;</label></span>
          </div>
          <div className="button" id="btn-last" onClick={lastPageBtnHandler}>
            <span><input type='button' name='surface-display' value='最後へ' id='page-last' /><label htmlFor='surface-last'>最後へ &gt;&gt;</label></span>
          </div>
      </div>
      <div class="question"><a href="/help/operation" target='_blank'>操作ボタンの説明　<img src="/images/common/question.png" alt="" /></a></div>
    </section>
  ) 
}

export default EditorTopMenu
