import React ,{} from 'react'
import titleImg from "../images/header/title.png"
import "./header.css"
function HeaderForStep(props:PropsValue) {




  return(
    <header id="header">
      <section id="headerin">
        <div className="titlebox">
          <h2 id="title"><img src={titleImg} alt="あさひ高速印刷オンラインショップ 圧着はがきDM印刷" /></h2>
        </div>
      </section>
    </header>
  )
}

export default HeaderForStep
