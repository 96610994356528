import React, {  useEffect } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'


function InqueryConfirmApp(props:PropsValue) {

  document.title = "お問い合わせ 送信完了｜圧着はがきDM印刷";

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const back = () =>{

    window.location.href = "/"

  }



  return(

    <div id="page" className="page contact completion">
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
            <h1 className="stitle">お問い合わせ</h1>
            <h2 className="stitle3">送信完了</h2>
            <p>お問い合わせの送信を完了いたしました。<br/>
内容の控えをお送りしておりますのでご確認ください。
</p>



              <div className="buttonbox">
                <div className="button or">
                  <div onClick={back}>トップページへ</div>
                </div>
              </div>

              </section>
          </article>
          <Footer />
        </div>


  )
}

export default InqueryConfirmApp
