import React, {  useEffect,useState } from 'react'

import Header from '../../header/HeaderForStep'
import useAuthState from '../../auth/useAuthState'
import OrderForm from './OrdeForm'
import RequestValue from '../RequestValue'
import useCartState from '../cart/useCartState'

function PrintDetailApp(props:PropsValue) {
  document.title = "印刷内容を選択・料金確認｜圧着はがきDM印刷";

  var _is_loading = false
  const queryParameters = new URLSearchParams(window.location.search)
  const type = queryParameters.get("type")
  const {token, auth} = useAuthState()
  const {cart, current_cart_id, getCart, getCurrentCartId} = useCartState()
  const [item, setItem] = useState(null)
  useEffect(() => {
    auth()

    if(type !== "new"){
      getCurrentCartId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {

    getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);

  useEffect(() => {
    if(type !== "new"){
      getCurrentCart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {

    if(cart){
      const _cart = cart
      _cart.csv = null
      _cart.sheet = cart.csv ? null : cart.sheet
      setItem(_cart)
    }

  },[cart ]);


  const getCurrentCart = () =>{

    if(token && current_cart_id && !cart && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
    }
  }



  const moveToNextStep = (item) => {
    switch (item.plan) {
      case RequestValue.PLAN_NO_ATENA:
        window.location.href = "/order/step3"
        break;
      default:
          window.location.href = "/order/step2"
        break;
    }

  }

  return(

        <div id="step" className="step step1">
          <Header token={token} />
          <form encType="multipart/form-data" id="stepOne">
            <article id="contents">

              <section className="s-contentsin topcomment">
                  <h1>印刷内容を選択・料金確認</h1>
                  <p className="max-width-800">必須項目を選択し、画面下の「料金お見積り」で価格を確認してください。<br />
                  料金算出後に次のステップへお進みいただけます。</p>
              </section>
              <section className="s-contentsin2 section form">
                  <OrderForm token={token} item={item} moveToNextStep={moveToNextStep}/>
              </section>
              </article>
          </form>

        </div>


  )
}

export default PrintDetailApp
