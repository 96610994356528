import React, { } from 'react'

function VariableApp(props:PropsValue) {
 
  return(

       
    <div id="help">
      <header id="header">
        <h1>中面可変について</h1>
      </header>

      <article id="contents">
        <section className="contentsin">
          <section className="contents-box">
            <div className="title-box">
              <h2>可変項目について</h2>
            </div>
            <div className="comment-box">
              <p>郵送先お一人お一人に異なる情報をお届けしたいときにご利用いただける項目が「可変項目」です。<br/>
              「可変項目」は最大３つまで、圧着印刷のどの面にも配置可能です。<br/>
              また、３つの「可変項目」の内どれか一つをQRコードに変換し、バーコードとして表示することが可能です。<br/>
              例）お客様への請求金額やID、パスワードのご案内など</p>
              <div className="img">
                <img src="../images/help/background.jpg" alt=""/>
              </div>
            </div>
          </section>


        </section>
      </article>
    </div>


  )
}

export default VariableApp
