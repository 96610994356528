import React, { } from 'react'

function OperationApp(props:PropsValue) {
 
  return(

       
    <div id="help">
    <header id="header">
	<h1>操作ボタンの説明</h1>
</header>

<article id="contents">
	<section className="contentsin">
		<section className="contents-box">
			<div className="title-box">
				<h2>ボックス（宛名、可変項目、QRコード）の移動</h2>
			</div>
			<div className="comment-box">
				<p>ボックスは、3パターンで動かすことができます。</p>
				<ul>
					<li>
						<strong>マウスで移動</strong><br/>
						動かしたいボックスをクリックし、ドラッグアンドドロップで移動してください。
					</li>
					<li>
						<strong>キーボードで移動</strong><br/>
						動かしたいボックスをクリックし、選択した状態からキーボードで移動してください。
					</li>
					<li>
						<strong>座標を変更して移動</strong><br/>
						動かしたいボックスをクリックし、右横に表示されているパネルの「Ｘ（座標）」、「Ｙ（座標）」の数値を変更して移動してください。<br/>
						<br/>
						<div className="img">
							<img src="../images/help/operation-box-izhi.jpg" alt=""/>
						</div>
					</li>
				</ul>
				<br/>
				<p><strong>【重要】ボックスを動かしてエラーとなるパターン</strong><br/>
				・ボックスを用紙からはみ出して配置した時。<br/>
				・ボックスを重ねて配置した時。<br/>
				※ボックスは、全印刷の中で一番長い文字数を基準に重なり判定を行っています。<br/>
				重なりがわかり辛いときは、サンプルモード（一番長い文字数を配置した）で移動を行ってください。</p>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>ボックス（宛名、可変の項目、QRコード）の大きさ変更</h2>
			</div>
			<div className="comment-box">
				<ul>
					<li>
						<strong>マウスで変更</strong><br/>
						変更したいボックスをクリックし、ボックスの周りに表示された「●」にマウスを合わせ、ボックスの拡大、縮小と大きさを変更してください。
					</li>
					<li>
						<strong>座標を変更して移動</strong><br/>
						変更したいボックスをクリックし、右横に表示されているパネルの「幅」、「高さ」の数値を変更して移動してください。<br/>
						<br/>
						<div className="img">
							<img src="../images/help/operation-box-size.jpg" alt=""/>
						</div>
					</li>
				</ul>
				<br/>
				<p><strong>【注意点】</strong><br/>
				ボックスの枠の大きさは、中の文字が/収まる大きさにしてください。<br/>
				ボックスを文字より小さくしますと、他のボックスと重なり印刷される可能性があります。</p>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>文字を変更</h2>
			</div>
			<div className="comment-box">
				<p>文字の種類を変更します。<br/>
				変更したいボックスをクリックし、右横に表示されている「文字」からお好きなフォントをお選びください。</p>
				<div className="img">
					<img src="../images/help/operation-font.jpg" alt=""/>
				</div>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>文字の位置を変更</h2>
			</div>
			<div className="comment-box">
				<p>ボックスの範囲内で、フォントの位置が変更します。<br/>
				変更したいボックスをクリックし、右横に表示されている「位置合わせ」から位置（左寄せ・中央・右寄せ）をお選びください。</p>
				<div className="img">
					<img src="../images/help/operation-font-ichi.jpg" alt=""/>
				</div>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>文字のサイズを変更</h2>
			</div>
			<div className="comment-box">
				<p>文字の大きさを変更します。<br/>
				変更したいボックスをクリックし、右横に表示されている「サイズ」から数字を変更してください。</p>
				<div className="img">
					<img src="../images/help/operation-size.jpg" alt=""/>
				</div>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>文字の行の高さを変更</h2>
			</div>
			<div className="comment-box">
				<p>行の間隔を調整します。<br/>
				変更したいボックスをクリックし、右横に表示されている「行の高さ」から数字を変更してください。</p>
				<div className="img">
					<img src="../images/help/operation-font-gyou.jpg" alt=""/>
				</div>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>文字の感覚を変更</h2>
			</div>
			<div className="comment-box">
				<p>文字と文字との間隔が調整します。<br/>
				変更したいボックスをクリックし、右横に表示されている「行の高さ」から数字を変更してください。</p>
				<div className="img">
					<img src="../images/help/operation-kankaku.jpg" alt="" />
				</div>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>文字の色を変更</h2>
			</div>
			<div className="comment-box">
				<p>文字と色を変更します。文字の色は、ボックス内の文字すべてに適応されます。<br/>
				変更したいボックスをクリックし、右横に表示されている「文字の色」を選択して、パレットからお好きな色をお選びください。</p>
				<div className="img">
					<img src="../images/help/operation-font-color.jpg" alt=""/>
				</div>
				<br/>
				<p>【ご注意】<br/>
				背景の色に酷似した場合、見づらくなる可能性がございますので、注意して色をご選択ください。</p>
			</div>
		</section>


		<section className="contents-box">
			<div className="title-box">
				<h2>BOXの背景色を変更</h2>
			</div>
			<div className="comment-box">
				<p>BOXの背景色を変更します。
				変更したいボックスをクリックし、右横に表示されている「BOXの背景色」を選択して、パレットからお好きな色をお選びください。<br/>
				背景を透過にしたい時は、右横の「×」ボタンをクリックしてください。<br/>
				背景が元の透過状態に戻ります。</p>
				<div className="img">
					<img src="../images/help/operation-font-back.jpg" alt=""/>
				</div>
				<br/>
				<p>【ご注意】
				背景の色に酷似した場合、見づらくなる可能性がございますので、注意して色をご選択ください。</p>
			</div>
		</section>

	</section>
</article>
    </div>


  )
}

export default OperationApp
