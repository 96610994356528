import React, {  useEffect,useState } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'
import UserMenu from '../menu/UserMenu'
import useDeliveryState from './useDeliveryState'
import DeliveryList from './DeliveryList'

import DeliveryDetail from './DeliveryDetail'

function DeliveryApp(props:PropsValue) {

  document.title = "追加配送先｜圧着はがきDM印刷";

  const [message, setMessage] = useState(null)
  const [delivery, setDelivery] = useState(null)
  const {token, auth} = useAuthState()


  const {delivery_list, created_delivery_id, delivery_list_updated_at, getDeliveryList, updateDelivery, deleteDerivery, createDelivery} = useDeliveryState()

  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    if(created_delivery_id)
      setMessage("配送先を追加しました。")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[created_delivery_id]);

  useEffect(() => {
    if(token)

      getDeliveryList(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  useEffect(() => {
    if(delivery_list_updated_at)
      if(delivery_list.length < 6){
        setDelivery(<DeliveryDetail index={delivery_list.length} submit={createHandler} delete={false}  created_delivery_id={created_delivery_id} caption="追加配送先を新規に追加" /> )
      }else{
        setDelivery(null)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[delivery_list_updated_at]);


  const deleteHandler = (item) => {
    deleteDerivery(token, item)
  }

  const updateHandler = (item) => {
    setMessage(null)

    updateDelivery(token, item)
  }

  const createHandler = (item) => {
    setMessage(null)
    createDelivery(token, item)


  }

  return(

        <div id="page" >
          <Header token={token} />
          <Menu />
          <form enctype="multipart/form-data" id="stepOne">
            <article id="contents">
              <UserMenu selectMenuItem="delivery" />
              <section className="s-contentsin2 topcomment1">
                  <h1 className="stitle">追加配送先</h1>
                  <p>会員情報とは別の配送先を最大6件まで登録可能です。<br/>必須項目を入力し、追加配送先の登録を行ってください。</p>

              </section>
              <section className="s-contentsin2 delivery-list" >
                {message ? <div style={{color: "#0075c1", fontSize:"2em"}} className="text-center py-3">{message}</div> : null}
                {delivery}
               </section>
              <section className="s-contentsin2 delivery-list" id="list">
                <h2 class="stitle2">登録している追加配送先一覧</h2>
                  {delivery_list ? <DeliveryList updated_at={delivery_list_updated_at}  items={delivery_list} delete={deleteHandler} submit={updateHandler}/> :  null }
              </section>
              </article>
          </form>
          <Footer />
        </div>


  )
}

export default DeliveryApp
