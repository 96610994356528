import React, { useState, useEffect } from 'react'
import useMediaState from '../media/useMediaState';
import './3d.css'
import RequestValue from '../RequestValue';
import vvvimg from "../../images/svg/v_cover_rw.svg"
import vvvimg_r from "../../images/svg/vvvvv.png"
import zzzimg from "../../images/svg/z_cover_b2.svg"
function Pdf3dPreview(props:PropsValue) {
  
  const [item, setItem] = useState(null)
  const [front_img_sr, setFrontImageSrc] = useState(null)
  const [back_img_sr, setBackImageSrc] = useState(null)
  const {loaded_image,loaded_images_length,getImage } = useMediaState()
  
  useEffect(() => {
    if(props.item){
      setItem(props.item)
     if(props.item.pdf_front_path){
      getImage(props.token, props.item.id, 1)
     }
     if(props.item.pdf_back_path){
      getImage(props.token, props.item.id, 2)
     }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);

  useEffect(() => {
    if(loaded_image){
     
      const _front = loaded_image.find(_img => _img.type === 1)
      
      if(_front){
        setFrontImageSrc(_front.data) 
      }

      const _back = loaded_image.find(_img => _img.type === 2)
      if(_back){
        setBackImageSrc(_back.data) 
      }
    }
  },[loaded_image, loaded_images_length]);
  



  return(
    <div className={item && item.specification === RequestValue.SPECIFICATION_Z ?  "imagebox z" :  "imagebox v" }>
				<div className="img l">
					<div className="image">
          {front_img_sr ? item.specification === RequestValue.SPECIFICATION_Z ? 
            <div className="a3dview-box type-z">
             <div className="left"><div className="in"><img src= {props.url.createObjectURL(front_img_sr) }alt="" /> </div></div>
             <div className="center"><div className="in"><img src= {props.url.createObjectURL(front_img_sr) }alt="" /></div></div>
             <div className="right"><div className="in"><img src= {props.url.createObjectURL(front_img_sr) }alt="" /></div></div>
             <div className="cover"><img src={zzzimg} alt="cover" /></div> 
            </div>
            :
            <div className="a3dview-box type-v">
              
              <div className="left"><div className="in"><img src= {props.url.createObjectURL(front_img_sr) }alt="" /></div></div>
              <div className="right"><div className="in"><img src= {props.url.createObjectURL(front_img_sr) }alt="" /></div></div>
              <div className="cover"><img src={vvvimg} alt="cover" /></div> 
            </div>

          : null }
						
					</div>
					
				</div>
				<div className="img r">
					<div className="image r">
         
          {back_img_sr ? item.specification === RequestValue.SPECIFICATION_Z ? 
            <div className="a3dview-box type-z">
             <div className="left"><div className="in"><img src= {props.url.createObjectURL(back_img_sr) }alt="" /></div></div>
             <div className="center"><div className="in"><img src= {props.url.createObjectURL(back_img_sr) }alt="" /></div></div>
             <div className="right"><div className="in"><img src= {props.url.createObjectURL(back_img_sr) }alt="" /></div></div>
             <div className="cover"><img src={zzzimg} alt="cover" /></div> 
            </div>
            :
            <div className="a3dview-box type-v r">
            <div className="left"><div className="in"><img src= {props.url.createObjectURL(back_img_sr) }alt="" /></div></div>
            <div className="right"><div className="in"><img src= {props.url.createObjectURL(back_img_sr) }alt="" /></div></div>
            <div className="cover"><img src={vvvimg_r} alt="cover" /></div> 
          </div>
          : null }
					</div>
					
				</div>
			</div>
  )
}

export default Pdf3dPreview
